import React, { useEffect, useState } from 'react'
import './MenuLink.scss'

import Button from '../Button'

import { ReactComponent as IqonOpen } from '../../assets/iqons/iqon_open.svg'


const MenuLink = ({ center, left, menuItems, name, right, to }) => {

    const [viewMenu, setViewMenu] = useState(false)
    const [justify, setJustify] = useState('jcFlex-start')

    useEffect( () => {
        if (center) setJustify('jcCenter')
        else if (left) setJustify('jcFlex-end')
        else if (right) setJustify('jcFlex-start')
    }, [center, left, right])


    return (
        <span
            className="MenuLink__container"
            onMouseLeave={() => setViewMenu(false)}
        >
            {!menuItems && <Button
                css="MenuLink__link"
                action={to}
            >
                {name}
            </Button>}
            {menuItems && <span
                className="MenuLink__link" 
                onMouseEnter={() => setViewMenu(true)}
            >
                {name}
                <IqonOpen />
            </span>}
            {viewMenu && menuItems && <div className={`row c12 MenuLink__menu--container p00 ${justify}`}>
                <div className="row MenuLink__menu--bg p20">
                    {menuItems.map( (i, idx) =>
                        <Button css="row c06 MenuLink__item" key={idx} action={i.to}>
                            <div className="row MenuLink__veqtor">
                                {i.iqon}
                            </div>
                            <div className="column MenuLink__info px15">
                                <span className="MenuLink__info--title">{i.name}</span>
                                <span className="MenuLink__info--description">{i.description}</span>
                            </div>
                        </Button>
                    )}
                </div>
            </div>}
        </span>
    )
}

export default MenuLink
