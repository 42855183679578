import React from 'react'
import { useAppWidth } from '../../Provider'
import './FooterCta.scss'

import Button from '../Button'


const FooterCta = ({ action, btnText }) => {

    const vw = useAppWidth()


    return (
        <div className="row c12 FooterCta__container fullHeight p00 mt100 aCenter">
            <div className="column c12 my100 aCenter">
                <h2 className="taCenter">
                    Empieza a usar hoy
                    {vw >= 768 && <br />} la tecnología del mañana
                </h2>
                <Button css="btn btn--primary btn--grow btn--cta" action={action}>{btnText}</Button>
            </div>
        </div>
    )
}

export default FooterCta
