import React, { useEffect, useState } from 'react'
import './Select.scss'

import { ReactComponent as IqonOpen } from '../../assets/iqons/iqon_open.svg'


const Select = ({
    activeValue,
    children,
    disabled,
    error,
    height,
    placeOption,
    slice,
    success,
    width
}) => {

    const [showOptions, setShowOptions] = useState(false)
    const [displayedValue, setDisplayedValue] = useState(null)

    useEffect(() => {
        if (window.PointerEvent) document.addEventListener("pointerup", handleEvent)
        else {
            document.addEventListener("mouseup", handleEvent)
            document.addEventListener("touchend", handleEvent)
        }

    return () => {
        if (window.PointerEvent) document.removeEventListener("pointerup", handleEvent)
        else {
            document.removeEventListener("mouseup", handleEvent)
            document.removeEventListener("touchend", handleEvent)
        }
    }
    }, [])

    useEffect( () => {
        if (activeValue && slice && activeValue.length > slice) {
            const newValue = activeValue.slice(0, slice)
            setDisplayedValue(newValue + '...')
        }
        else if (activeValue) setDisplayedValue(activeValue)
    }, [activeValue, slice])

    const handleEvent = e => { 
        if (e.target.getAttribute('inside') === 'true') return
        setShowOptions(false)
    }


    return (<>

        {/* {showOptions && <div className="Select__listener" onClick={() => setShowOptions(false)} />} */}

        <div
            className="Select__container"
            style={{width: width ? width : '100%'}}
        >
            <div
                className={`Select__active-value
                    ${displayedValue || 'placeholder'}
                    ${disabled || null}
                    ${success && 'success'}
                    ${error && 'error'}
                `}
                onClick={() => {
                    if (disabled) return
                    else setShowOptions(!showOptions)
                }}
            >
                {displayedValue || placeOption}
            </div>
            <IqonOpen
                className="Select__iqon"
                onClick={() => {
                    if (disabled) return
                    else setShowOptions(!showOptions)
                }}
            />
            {showOptions &&
                <div
                    className="Select__options"
                    inside="true"
                    style={{
                        width: width ? width : '100%',
                        maxHeight: height ? height : 'fit to content'
                    }}
                    onClick={() => setShowOptions(false)}
                >
                    {children}
                </div>
            }
        </div>
    </>)
}

export default Select
