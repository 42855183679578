import React, { useEffect, useRef, useState } from 'react'
import './MobileMenuLink.scss'

import Button from '../Button'

import { ReactComponent as IqonOpen } from '../../assets/iqons/iqon_open.svg'


const MobileMenuLink = ({ menuItems, name, to }) => {

    const menuRef = useRef()
    const [viewMenu, setViewMenu] = useState(false)
    const [height, setHeight] = useState(0)


    useEffect( () => {
        if (menuItems) {
            console.log(name, menuRef.current.clientHeight)
            setHeight(menuRef.current.clientHeight)
        }
    },[menuItems])


    return (<>
        <div className="row c12 MobileMenuLink__container">
            {!menuItems && <Button
                    css="MobileMenuLink__link"
                    action={to}
                >
                    {name}
                </Button>}
            {menuItems && <span
                    className={`MobileMenuLink__link ${viewMenu ? 'active' : undefined}`}
                    onClick={() => setViewMenu(!viewMenu)}
                >
                    {name}
                    <IqonOpen />
                </span>}
        </div>
        {menuItems &&
            <div
                className="row c12 MobileMenuLink__menu p00 aFlex-start"
                style={{height: viewMenu ? `${height}px` : '0px'}}
                
            >
                <div className="row c12 pt00 px00" ref={menuRef}>
                    {menuItems.map( (i, idx) =>
                        <Button css="row c12 MobileMenuLink__item" key={idx} action={i.to}>
                            <div className="row MenuLink__veqtor">
                                {i.iqon}
                            </div>
                            <div className="column MobileMenuLink__info px15">
                                <span className="MobileMenuLink__info--title">{i.name}</span>
                                <span className="MobileMenuLink__info--description">{i.description}</span>
                            </div>
                        </Button>
                    )}
                </div>
        </div>}
    </>)
}

export default MobileMenuLink
