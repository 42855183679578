import React, { useState } from 'react'
import { useAppHeight, useAppWidth } from '../../Provider'
import './Header.scss'

import MenuLink from '../MenuLink'
import MobileMenuLink from '../MobileMenuLink'
import Button from '../Button'

import logo from '../../assets/images/logo_Qibo-io-10.svg'
import { ReactComponent as IqonBuildings } from '../../assets/iqons/iqon_buildings.svg'
import { ReactComponent as IqonDNA } from '../../assets/iqons/iqon_dna.svg'
import { ReactComponent as IqonEthics } from '../../assets/iqons/iqon_ethics.svg'
import { ReactComponent as IqonHeart } from '../../assets/iqons/iqon_heart.svg'
import { ReactComponent as IqonHeadphones } from '../../assets/iqons/iqon_call-headphones.svg'
import { ReactComponent as IqonKey } from '../../assets/iqons/iqon_key.svg'
import { ReactComponent as IqonLifesaver } from '../../assets/iqons/iqon_lifesaver.svg'
import { ReactComponent as IqonLike } from '../../assets/iqons/iqon_like.svg'
import { ReactComponent as IqonMegaphone } from '../../assets/iqons/iqon_megaphone.svg'
import { ReactComponent as IqonMessageBubbles } from '../../assets/iqons/iqon_message-bubbles.svg'
import { ReactComponent as IqonTyping } from '../../assets/iqons/iqon_typing.svg'


const Header = ({ signUp }) => {

    const vh = useAppHeight()
    const vw = useAppWidth()

    const [solutionsMenu] = useState([
        {
            name: 'Empresarial y seguridad',
            description: 'Detecta los focos rojos en el personal que trabaja o va a trabajar en tu empresa',
            iqon: <IqonBuildings />,
            to: '/biz'
        },
        {
            name: 'Hogar y familia',
            description: 'Conoce a las personas que cuidan y conviven con tu familia',
            iqon: <IqonHeart />,
            to: '/home'
        }
        // {
        //     name: 'Call center',
        //     description: 'Identifica la emociones de tus clientes en tiempo real',
        //     iqon: <IqonHeadphones />,
        //     to: '/'
        // },
        // {
        //     name: 'Arrendamiento de inmuebles',
        //     description: 'A quién le vas a arrendar tu inmueble, ¿tiene la intención y solvencia de pago o hará mal uso de él?',
        //     iqon: <IqonKey />,
        //     to: '/'
        // },
    ])
    const [knowUsMenu] = useState([
        {
            name: 'Nuestra historia',
            description: '10 años de historia. Conoce cómo Ethics se convirtió en Qibo®',
            iqon: <IqonEthics />,
            to: '/history'
        },
        {
            name: 'Qibo® en los medios',
            description: 'Conoce lo que los medios dicen sobre nuestra tecnología',
            iqon: <IqonMegaphone />,
            to: '/press'
        }
        // {
        //     name: 'Casos de éxito',
        //     description: 'Hemos ayudado a más de 100 empresas y hogares a detectar sus riesgos',
        //     iqon: <IqonLike />,
        //     to: '/'
        // },
        // {
        //     name: 'Nuestro ADN',
        //     description: 'Conoce nuestra misión, visión y valores',
        //     iqon: <IqonDNA />,
        //     to: '/'
        // }
    ])
    const [moreMenu] = useState([
        {
            name: 'Centro de ayuda',
            description: 'Te resolvemos las dudas más comunes acerca de nuestros servicios',
            iqon: <IqonLifesaver />,
            to: '/help-center'
        },
        // {
        //     name: 'Blog',
        //     description: 'Tenemos mucho que compartirte. Date una vuelta y lee nuestros últimos artículos',
        //     iqon: <IqonTyping />
        // },
        {
            name: 'Contacta a ventas',
            description: '¿Tienes alguna pregunta o estás listo para empezar a usar Qibo®? Este es tu lugar',
            iqon: <IqonMessageBubbles />,
            to: '/contact'
        },
    ])
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [leavingMobileMenu, setLeavingMobileMenu] = useState(false)

    const handleMobileMenuAnimation = () => {
        if (!showMobileMenu) setShowMobileMenu(true)
        else {
            setLeavingMobileMenu(true)
            setShowMobileMenu(false)
            setTimeout( () => {
                setLeavingMobileMenu(false)
            }, 400)
        }
    }

    const CompleteMenu = () =>
        <div className="row c04 Header__links p00 mr40 aCenter">
            <MenuLink
                name="Producto"
                menuItems={solutionsMenu}
            />
            <MenuLink
                name="Hallazgos"
                to="/ranking"
            />
            <MenuLink
                name="Conócenos"
                menuItems={knowUsMenu}
                center
            />
            <MenuLink
                name="Más"
                menuItems={moreMenu}
                center
            />
            <Button
                css="btn btn--stroke btn--primary mr10 mlAuto"
                action="https://app.qibo.io/#/login"
            >
                Iniciar sesión
            </Button>
            {signUp && <Button
                css="btn btn--primary btn--grow ml10"
                action="https://app.qibo.io/#/login"
            >
                Crear cuenta
            </Button>}
            {!signUp && <Button
                css="btn btn--primary btn--grow ml10"
                action="/contact"
            >
                Contáctanos
            </Button>}
        </div>

    const mobileMenu = () => <>
        <div className="row c04 Header__links p00 mr20 aCenter jcFlex-end">
            <span
                className="column Header__links--hamburger" 
                onClick={handleMobileMenuAnimation}
            >
                <span className={`first ${showMobileMenu ? 'active' : undefined}`} />
                <span className={`second ${showMobileMenu ? 'active' : undefined}`} />
            </span>
        </div>
        {(showMobileMenu || leavingMobileMenu) && 
            <div
                className={`row c12 Header__links--screen pt100 aFlex-start
                    ${leavingMobileMenu ? 'leave-right' : 'enter-right'}
                `}
                style={{width: `${vw}px`, height: `${vh}px`}}
            >
                <MobileMenuLink
                    name="Producto"
                    menuItems={solutionsMenu}
                />
                <MobileMenuLink
                    name="Hallazgos"
                    to="/ranking"
                />
                <MobileMenuLink
                    name="Conócenos"
                    menuItems={knowUsMenu}
                />
                <MobileMenuLink
                    name="Más"
                    menuItems={moreMenu}
                />
                <div className="column c12 p00">
                    {signUp && <Button
                        css="btn btn--primary"
                        action="https://app.qibo.io/#/login"
                    >
                        Crear cuenta
                    </Button>}
                    {!signUp && <Button
                        css="btn btn--primary"
                        action="/contact"
                    >
                        Contáctanos
                    </Button>}
                    <Button
                        css="btn btn--primary btn--stroke"
                        action="https://app.qibo.io/#/login"
                    >
                        Iniciar sesión
                    </Button>
                </div>
            </div>}
    </>


    return (<>

        <header className="row Header">

            <div className="column Header__logo">
                <Button action="/"><img src={logo} alt="Qibo logo" /></Button>
            </div>
            
            {vw >= 1200 && <CompleteMenu />}
            {vw < 1200 && mobileMenu()}

        </header>

    </>)
}

export default Header
