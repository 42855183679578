import React from 'react'
import './TermsPage.scss'

import Header from '../../components/Header'
import Footer from '../../components/Footer'


const TermsPage = () => {
    return (<>

        <Header />

        <div className="container mb100">
            <div className="column c12 TermsPage__title">
                <h1 className="mb00">Términos y<br /><span className="txt--accent">condiciones</span></h1>
                <p className="p--gray taLeft"><strong>Última actualización:</strong> 12 (doce) de abril de 2021 (dos mil veintiuno)</p>
            </div>
            <div className="column c12 TermsPage__body p00 aFlex-end">
                <div className="column c12 TermsPage__section--text">
                    <p><strong>CONTRATO DE ADHESIÓN RESPECTO DEL USO, ACCESO Y PRESTACIÓN DE SERVICIOS, (EN LO SUCESIVO, “TÉRMINOS Y CONDICIONES”) DE LA PLATAFORMA QIBO.IO (COMO REFERENCIA, LA “PLATAFORMA”) QUE CELEBRAN, POR UNA PARTE, ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V. (EN ADELANTE, “QIBO”) Y, POR LA OTRA, EL USUARIO, SIENDO LOS ANTERIORES EN SU CONJUNTO REFERIDOS COMO LAS “PARTES”, SUJETÁNDOSE AMBOS AL TENOR DE LAS SIGUIENTES CLÁUSULAS.</strong></p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Definiciones</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p>Para efectos del presente instrumento, los términos enumerados a continuación, ya sea en singular o plural, tendrán las siguientes definiciones:</p>
                    <ol className="roman">
                        <li><strong>Aviso de Privacidad.-</strong> Documento puesto a disposición del público en la Plataforma, en el que <strong>QIBO</strong> hace del conocimiento de los Usuarios qué Datos Personales son recabados, las finalidades de su tratamiento, así como las formas, mecanismos y requisitos para el ejercicio de sus derechos de acceso, rectificación, cancelación y oposición, amén de los medios para realizar la revocación de su consentimiento y la limitación del uso o divulgación de su información.</li>
                        <li><strong>Cuenta de Usuario.-</strong> Cuenta única, intransferible y personal de los Usuarios que realicen su registro en la Plataforma, ya sea en la modalidad de “Cuenta Personal” o “Cuenta Empresarial”.</li>
                        <li><strong>Datos Personales.-</strong> Cualquier información concerniente al Usuario, o a cualquier persona física identificada o identificable.</li>
                        <li><strong>Derechos ARCO.-</strong> Derechos de acceso, rectificación, cancelación y oposición del titular de Datos Personales.</li>
                        <li><strong>Plataforma.-</strong> Sitio web operado por <strong>QIBO</strong> dirigido a gestionar los servicios de análisis de voz de entrevistas que presta <strong>QIBO</strong>, así como la administración de los resultados del análisis de voz y diseño de cuestionarios para entrevistas de evaluación.</li>
                        <li><strong>PayPal.-</strong> Plataforma de pagos operada por Operadora PayPal de México, S. de R.L. de C.V.</li>
                        <li><strong>Qibo.-</strong> ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V.</li>
                        <li><strong>Servicios.-</strong> Análisis de voz de entrevistas, administración de los resultados del análisis de voz y diseño de cuestionarios para entrevistas de evaluación.</li>
                        <li><strong>Términos y Condiciones.-</strong> El presente contrato de adhesión en toda su extensión.</li>
                        <li><strong>Tokens.–</strong> Método de solicitud y adquisición de los Servicios; en términos generales, los créditos generados en favor del Usuario por el prepago de los Servicios.</li>
                        <li><strong>Uso.-</strong> <strong>(a)</strong> El acceso y utilización de la Plataforma; <strong>(b)</strong> el registro y creación de una Cuenta de Usuario. <strong>(c)</strong> la contratación de los Servicios; <strong>(d)</strong> la programación de entrevistas; <strong>(e)</strong> la contratación de análisis de las entrevistas programadas; y <strong>(f)</strong> el pago del análisis de las entrevistas.</li>
                        <li><strong>Usuario.-</strong> Persona que ingresa, navega y/o utiliza la Plataforma para cualquier finalidad, así como el Usuario Administrador en caso de que se trate de una Cuenta Empresarial.</li>
                    </ol>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Objeto</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>2.1. Objeto y Alcance del Contrato.</strong> Los presentes Términos y Condiciones establecen los derechos y obligaciones que gozarán y a las que se sujetarán <strong>QIBO</strong> y los Usuarios por el Uso de la Plataforma. Las partes convienen en que la solicitud y prestación de los Servicios se regirán también por los presentes Términos y Condiciones.</p>
                    <p><strong>2.2. Uso de la Plataforma.</strong> Comprende de manera general: <strong>(i)</strong> el acceso y utilización la Plataforma; <strong>(ii)</strong> el registro y creación de una cuenta de usuario; <strong>(iii)</strong> la contratación de los Servicios; <strong>(iv)</strong> la programación de entrevistas; <strong>(v)</strong> la contratación del análisis de voz de las entrevistas; y <strong>(vi)</strong> el pago de los Servicios.</p>
                    <p><strong>2.3. Licencia.</strong> <strong>QIBO</strong> concede al Usuario una licencia no exclusiva, no transferible y revocable para utilizar la Plataforma en virtud de los presentes Términos y Condiciones. Esta licencia es conferida con un fin y carácter enteramente personal, nunca comercial.</p>
                    <p><strong>2.4. Límites de la Licencia.</strong> El Usuario no podrá imprimir, descargar, reproducir o copiar cualquier información, archivo, texto, video, audio, imagen contenidos o publicado en la Plataforma o en los entregables fruto de los Servicios. Queda prohibida la reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, imagen, documento, audio o gráfico que aparezca en la Plataforma o en los entregables fruto de los Servicios para cualquier uso distinto a los aquí autorizados. Cualquier infracción de estos Términos y Condiciones dará lugar a la revocación inmediata de la licencia otorgada en este apartado sin previo aviso y a la rescisión del presente contrato de adhesión.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Contratación</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>3.1. Consentimiento.</strong> Al ingresar y utilizar la Plataforma propiedad de <strong>QIBO</strong> , el Usuario aceptó electrónicamente los presentes Términos y Condiciones, que regulan el Uso de la Plataforma operada por <strong>QIBO</strong>, así como la prestación de los Servicio. En caso de no aceptar en forma absoluta y completa los presentes Términos y Condiciones, el Usuario deberá abstenerse de acceder y utilizar la Plataforma, así como de solicitar los Servicios. El consentimiento del usuario se manifiesta al pulsar el botón con la leyenda “He leído y acepto Términos y Condiciones y Aviso de Privacidad” o similar dentro de la Plataforma.</p>
                    <p><strong>3.2. Puesta a Disposición.</strong> Los presentes Términos y Condiciones serán puestos a disposición del Usuario en la Plataforma al momento de crear una Cuenta de Usuario para su acceso. De la misma manera, estarán disponibles para su posterior consulta en la Plataforma.</p>
                    <p><strong>3.3. Capacidad del Usuario.</strong> Desde el momento en el que acceden y hacen uso de la Plataforma, los Usuarios manifiestan que cuentan con capacidad de ejercicio suficiente para obligarse en todos los extremos establecidos en los presentes Términos y Condiciones.</p>
                    <p><strong>3.4. Menores de Edad.</strong> Desde el momento en el que hacen uso de la Plataforma, los Usuarios manifiestan, bajo protesta de decir verdad, que son mayores de edad, por lo que se obligan en todos los extremos establecidos en los presentes Términos y Condiciones. En el supuesto que los Usuarios sean menores de edad o incapaces, el acceso, uso y aprovechamiento de la Plataforma, su contenido, secciones y funcionalidades, así como la contratación de los Servicios se entenderán bajo la total y completa responsabilidad de sus respectivos padres o tutores.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Uso y acceso</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>4.1. Reglas.</strong> El registro, acceso y uso de los Servicios por cada Usuario deberá ser en todo momento personal, por lo que los derechos y obligaciones que tiene al amparo los Términos y Condiciones son personalísimos, únicos e intransferibles.</p>
                    <p><strong>4.2. Disponibilidad.</strong> La Plataforma están a disposición del Usuario las 24 (veinticuatro) horas del día, así como los 365 (trescientos sesenta y cinco) días del año, salvo los días en los que la Plataforma esté en mantenimiento o actualización para ofrecer experiencias optimizadas para los Usuarios. En todo caso, <strong>QIBO</strong> notificará la suspensión de la disponibilidad de la Plataforma vía correo electrónico o mediante un mensaje en la Plataforma.</p>
                    <p><strong>4.3. Prohibiciones.</strong> En el uso de la Plataforma y de los Servicios, el Usuario tendrá estrictamente prohibido:</p>
                    <ol className="roman">
                        <li>Difundir información falsa, engañosa o que induzca al error.</li>
                        <li>El comportamiento fraudulento, ilegal, amenazante, abusivo, hostigante, calumnioso, difamatorio, discriminatorio, obsceno, vulgar, ofensivo, pornográfico, profano, sexualmente explícito o indecente.</li>
                        <li>Generar o incentivar conductas que pudieran constituir un delito, dar lugar a responsabilidad civil o violar cualquier disposición legal.</li>
                        <li>Violar, plagiar o infringir los derechos de terceros incluyendo, sin limitación, derechos de autor, marcas, secretos comerciales, o industriales confidencialidad, contratos, patentes, derechos de privacidad o publicidad o cualquier otro derecho.</li>
                        <li>Diseminar virus, elementos de espionaje u otro componente dañino.</li>
                        <li>Retirar cualquier nota o aviso de derechos de autor o de marcas.</li>
                        <li>Reproducir, modificar, preparar obras derivadas sobre los entregables objeto de los Servicios.</li>
                        <li>Distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los entregables objeto de los Servicios. </li>
                        <li>Descompilar, realizar ingeniería inversa o desmontar la Plataforma.</li>
                        <li>Causar o lanzar cualquier programa o código informático con el objeto de extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de la Plataforma, o bien, sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto de la Plataforma.</li>
                        <li>Intentar obtener un acceso no autorizado o dañar cualquier aspecto de la Plataforma o sus sistemas o redes relacionados.</li>
                    </ol>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Cuenta de usuario</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>5.1. Registro y Creación de Cuenta.</strong> La creación de una Cuenta de Usuario se lleva a cabo directamente en la Plataforma. Para realizar el registro y creación de una Cuenta de Usuario se debe de seleccionar la opción correspondiente que se encuentra en la página principal de la Plataforma. Posteriormente, el Usuario deberá proporcionar la siguiente información requerida:</p>
                    <ol className="roman">
                        <li>Cuenta Personal: nombre, apellido, correo electrónico, teléfono y contraseña.</li>
                        <li>Cuenta Empresarial: previo al registro el prospecto de Usuario deberá realizar el pago de la inscripción. Una vez hecha la inscripción deberá proveer a <strong>QIBO</strong> la información que aparezca en el formulario correspondiente. Dicho formulario podrá contener los siguientes campos, sin que la siguiente lista sea taxativa:</li>
                        <ol className="latin mt10">
                            <li>Datos del usuario administrador:</li>
                            <ul>
                                <li>Denominación o razón social.</li>
                                <li>RFC.</li>
                                <li>Nombre y apellido.</li>
                                <li>Correo electrónico.</li>
                                <li>Puesto del usuario administrador.</li>
                                <li>Nombre comercial.</li>
                                <li>Grupo al que pertenece.</li>
                                <li>Giro.</li>
                                <li>Domicilio fiscal de la empresa.</li>
                                <li>Método de pago.</li>
                                <li>Forma de pago.</li>
                                <li>Últimos cuatro dígitos de cuenta bancaria.</li>
                            </ul>
                            <li>Datos del contacto de la empresa:</li>
                            <ul>
                                <li>Nombre y apellido.</li>
                                <li>Correo electrónico.</li>
                                <li>Teléfono.</li>
                                <li>Área de trabajo.</li>
                                <li>Puesto.</li>
                                <li>Domicilio laboral del contacto con la empresa.</li>
                            </ul>
                            <li>Datos del receptor de las facturas:</li>
                            <ul>
                                <li>Nombre y apellido.</li>
                                <li>Área de trabajo.</li>
                                <li>Puesto.</li>
                                <li>Correo electrónico.</li>
                                <li>Teléfono.</li>
                                <li>Domicilio laboral del receptor de facturas.</li>
                            </ul>
                        </ol>
                    </ol>
                    <p>En caso de no contar con todos los datos requeridos por la Plataforma, el prospecto de Usuario no podrá contratar los Servicios.</p>
                    <p><strong>5.2. Veracidad.</strong> El Usuario se obliga a proporcionar y mantener la información personal de su cuenta de forma exacta, completa y actualizada. La falta a ello podría derivar en la imposibilidad de acceder y utilizar la Plataforma y de contratar los Servicios en cualquiera de sus modalidades.</p>
                    <p><strong>5.3. Validación de Cuenta.</strong> En el caso de la Cuenta Personal, <strong>QIBO</strong> enviará un correo electrónico al correo electrónico que hubiere proporcionado el Usuario, a fin de que valide su Cuenta de Usuario. Y, en caso de la Cuenta Empresarial, <strong>QIBO</strong> enviará un correo electrónico al correo electrónico que hubiere proporcionado el Usuario con el propósito de que se establezca la contraseña del Usuario Administrador.</p>
                    <p><strong>5.4. Uso de la Cuenta de Usuario.</strong> La creación de una cuenta permite al Usuario lo siguiente:</p>
                    <ol className="roman">
                        <li>Cuenta Personal: solicitar entrevistas y consultar los resultados de sus respectivos análisis.</li>
                        <li>Cuenta Empresarial: solicitar entrevistas, consultar los resultados de sus análisis, organizar las entrevistas realizadas y los candidatos a entrevistar, generar métricas sobres los resultados del análisis, crear y administrar etiquetas y, crear y administrar usuarios dentro de la cuenta.</li>
                    </ol>
                    <p><strong>5.5. Responsabilidades.</strong> Cada Usuario es el único responsable de mantener su contraseña segura y secreta. El Usuario es totalmente responsable de todas las actividades que ocurran bajo su contraseña o cuenta. Por otra parte, el Usuario debe notificar a <strong>QIBO</strong> de cualquier uso no autorizado de su contraseña o cuenta. De ninguna manera <strong>QIBO</strong> será responsable, directa o indirectamente, por cualquier pérdida o daño de cualquier tipo incurridos como resultado de la falta de cumplimiento con esta sección por parte del Usuario.</p>
                    <p><strong>5.6. Características de la Cuenta.</strong> La Cuenta del Usuario es personal, única e intransferible. Está prohibida la venta, cesión, transferencia o transmisión de la Cuenta del Usuario bajo cualquier título, ya sea oneroso o gratuito.</p>
                    <p><strong>5.7. Cancelación de Cuenta.</strong> En caso de que el Usuario viole lo expresado en estos Términos y Condiciones, <strong>QIBO</strong> podrá cancelar su cuenta y/o el acceso a los Servicios independientemente de que <strong>QIBO</strong> podrá excluir al Usuario de futuras operaciones y tomar acciones legales. En este sentido, se hace saber al Usuario que el incumplimiento de estos Términos y Condiciones podrá derivar en la decisión de <strong>QIBO</strong> de excluir al Usuario de la Plataforma en los términos y extremos que <strong>QIBO</strong> estime conducentes. Para tales efectos, se le hará llegar una notificación mediante la Plataforma o los medios de contacto que hubiere proporcionado y se le harán saber los motivos por la cual se le excluye. Este proceso contará con un folio de referencia.</p>
                    <p><strong>5.8. Cancelación por el Usuario.</strong> El Usuario podrá requerir la terminación de su relación con <strong>QIBO</strong>. En tal caso, la relación con <strong>QIBO</strong> se dará por terminada en el momento en que el Usuario ingrese a la opción de “Eliminar Cuenta” que se encuentra en la sección de “Configuración de la Cuenta” dentro de la Plataforma. Ello derivará en la terminación anticipada de los presentes Términos y Condiciones sin necesidad de resolución judicial.</p>
                    <p><strong>5.9. Recuperación de Contraseña.</strong> En caso de olvidar su la contraseña de la Cuenta de Usuario, el Usuario deberá seleccionar la opción con la leyenda “Olvidé mi Contraseña” dentro de la Plataforma o cualquier otra leyenda similar. Posteriormente, se le solicitará el correo electrónico con el cual se creó la Cuenta de Usuario y deberá seguir las instrucciones que se especifiquen en el correo que le sea enviado para restablecer la contraseña.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Servicios</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>6.1. Servicios.</strong> <strong>QIBO</strong> ofrece a los Usuarios los siguientes servicios:</p>
                    <p>Realización de entrevistas a personas a través de una llamada telefónica para detectar los riesgos que representa dicha persona en cada una de las preguntas que se realiza. En este sentido, se ofrecen servicios de administración, análisis y métricas sobre los resultados de los análisis a un universo de entrevistados, a saber:</p>
                    <ol className="roman">
                        <li>Análisis de voz de entrevistas.</li>
                        <li>Administración de los resultados del análisis de voz.</li>
                        <li>Diseño de cuestionarios para entrevistas de evaluación.</li>
                    </ol>
                    <p>El Usuario reconoce que <strong>QIBO</strong> podrá modificar a discreción la información invocada en el presente inciso.</p>
                    <p><strong>6.2. Características de los Servicios.</strong> El Usuario reconoce que los servicios que brinda <strong>QIBO</strong> a través de la Plataforma tienen fines meramente informativos no representan opiniones expertas o técnicas, asesoría legal, psicológica, financiera o cualquier otra que requiera de estudios, conocimientos, experiencia y preparación específica en ramas determinadas del conocimiento.</p>
                    <p>El Usuario se compromete a utilizar la información obtenida a través de los Servicios para los siguientes fines:</p>
                    <ol className="roman">
                        <li>Cuentas Personales: El Usuario utilizará los entregables obtenidos como fruto de los Servicios para fines internos, personales y domésticos, por lo que se abstendrá de revelar su contenido a cualquier tercero, salvo en los casos que así lo exija la legislación aplicable.</li>
                        <li>Cuenta Empresarial: El Usuario utilizará los entregables obtenidos como fruto de los Servicios para fines internos y estrictamente relacionados con su operación, por lo que se abstendrá de revelar su contenido a cualquier tercero, salvo en los casos que así lo exija la legislación aplicable.</li>
                    </ol>
                    <p>El Usuario libera a <strong>QIBO</strong> de cualquier responsabilidad relacionada con el uso que le dé a los entregables fruto de los Servicios; por lo que  se obliga a sacar a <strong>QIBO</strong> en paz y a salvo de cualquier demanda, reclamación, procedimiento, requerimiento de autoridad o investigación que pudiera generar por su causa. El Usuario indemnizará a <strong>QIBO</strong> los gastos que realice por concepto de honorarios de abogados para atender cualquier demanda, reclamación, procedimiento, requerimiento de autoridad o investigación que pudiera generar por su causa.</p>
                    <p><strong>6.3. Precios.</strong> Los costos por cada concepto que forma parte de los Servicios estarán disponibles para consulta de los Usuarios en la Plataforma y podrán variar de tiempo en tiempo. El Usuario acepta y reconoce que <strong>QIBO</strong> podrá cambiar a discreción y con consecuencias jurídicas inmediatas los montos publicados en dicha sección de la Plataforma.</p>
                    <p><strong>6.4. Procedimiento de Solicitud de los Servicios.</strong> Para la contratación de los Servicios, el Usuario deberá seguir el procedimiento que se detalla a continuación:</p>
                    <ol className="roman">
                        <li>Cuenta Personal: para llevar a cabo la compra de entrevistas, el Usuario deberá realizar lo siguiente:</li>
                        <ol>
                            <li>Ingresar a la cuenta.</li>
                            <li>Dar clic en la sección de “entrevistados” o de “resultados”.</li>
                            <li>Dar clic sobre “adquirir entrevistas”. </li>
                            <li>Seleccionar la cantidad de entrevistas que desea comprar.</li>
                            <li>Si se tiene un cupón promocional, aplicarlo.</li>
                            <li>Dar clic en “pagar entrevistas” o “asignar entrevistas".</li>
                            <li>En caso que aplique, realizar el pago a través de la plataforma denominada PayPal.</li>
                        </ol>
                        <p className="mb00">Ahora bien, para que el Usuario pueda asignar un cuestionario deberá seguir el siguiente procedimiento:</p>
                        <ol>
                            <li>Ingresar a la cuenta.</li>
                            <li>Dar clic en la sección de “entrevistados”.</li>
                            <li>Dar clic sobre cualquier de las filas de las entrevistas disponibles.</li>
                            <li>Escribir el nombre o alias del entrevistado.</li>
                            <li>Seleccionar el cuestionario.</li>
                        </ol>
                        <li className="mt20">Cuenta empresarial (v. 5.0): para añadir a un candidato y posteriormente entrevistarlo, el Usuario Administrador deberá realizar lo siguiente:</li>
                        <ol>
                            <li>Ingresar a la cuenta.</li>
                            <li>Ir a la pestaña de “candidatos”.</li>
                            <li>Dar clic sobre el ícono de “agregar candidato”.</li>
                            <li>Llenar el formulario de alta de candidato.</li>
                            <li>Dar clic sobre “agregar candidato”.</li>
                        </ol>           
                        <li className="mt20">Cuenta empresarial (v. 7.0): para añadir a un candidato y posteriormente entrevistarlo, el Usuario Administrador deberá realizar lo siguiente:</li>
                        <ol>
                            <li>Ingresar a la cuenta.</li>
                            <li>Ir a la página de “resultados”.</li>
                            <li>Dar clic sobre el ícono de “agregar candidato”.</li>
                            <li>Llenar el formulario de alta de candidato.</li>
                            <li>Dar clic sobre “agregar candidato”.</li>
                        </ol>
                        <li className="mt20">Solicitud de Tokens: ahora bien, para que el Usuario Administrador pueda solicitar que se lleve a cabo el análisis de las entrevistas efectuadas a sus candidatos, previamente deberá contar con Tokens. Por lo que, para adquirirlos deberá seguir el siguiente procedimiento:</li>
                        <ol>
                            <li>Ingresar a la cuenta.</li>
                            <li>Ir a la página de “resultados”</li>
                            <li>Dar clic sobre “solicitar más tokens”.</li>
                            <li>Seleccionar la cantidad tokens que se requieren.</li>
                            <li>Dar clic sobre “solicitar tokens”</li>
                        </ol>
                    </ol>
                    <p>Los procesos anteriores podrán modificarse directamente en la Plataforma, sin necesidad de enmienda de los presentes Términos y Condiciones, por lo que se incluyen como mera referencia.</p>
                    <p><strong>6.5. Negativa para la Prestación de los Servicios.</strong> En este acto, <strong>QIBO</strong> se reserva el derecho de negar la prestación de los Servicios al Usuario en caso de que se actualice alguno de los siguientes supuestos:</p>
                    <ol className="roman">
                        <li>Cuando el servicio solicitado no esté disponible.</li>
                        <li>Cuando se soliciten Servicios relacionados con un hecho delictivo, en caso de que <strong>QIBO</strong> estime que representa un riesgo para su esfera jurídica y/o reputación.</li>
                        <li>Cuando no haya sido recibido el pago correspondiente, este no haya sido reconocido por PayPal o se hubiere registrado alguna irregularidad.</li>
                        <li>Cuando exista sospecha fundada de fraude o de una transacción no autorizada o ilegal.</li>
                        <li>Por comportamiento fraudulento, ilegal, amenazante, abusivo, calumnioso, Cuando el Usuario acceda o pretenda acceder a la Plataforma con fines ilícitos, ilegales, contrarios a los Términos y Condiciones, a la buena fe, las buenas costumbres, el orden público, la moral o con propósito de lesionar derechos e intereses de terceros.</li>
                        <li>En caso de acceder con el propósito de obtener un lucro indebido con respecto a los contenidos de la Plataforma.</li>
                        <li>Por realizar cualquiera de las conductas prohibidas que se mencionan en la Cláusula 4.3. de los presentes Términos y Condiciones.</li>
                    </ol>
                    <p><strong>6.6. Tokens.</strong> El Usuario acepta que para solicitar y adquirir los Servicios de <strong>QIBO</strong>, requerirá la compra de créditos de prepago denominados Tokens. Dichos Tokens tendrán una vigencia de 1 (un) año, por lo que deberán ser utilizados dentro de los 365 días siguientes a su adquisición. En caso de que el Usuario no los utilice en dicho plazo, deberá adquirir nuevos Tokens para contratar los Servicios. <strong>QIBO</strong> se compromete a enviar recordatorios periódicos al Usuario para que utilice sus Tokens previo a su vencimiento.</p>
                    <p>Así, para la adquisición de los tokens el Usuario debe seguir el procedimiento que se detalla en el punto 6.4., inciso (iv) detallado anteriormente.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Pago</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>7.1. Medios de Pago.</strong> El pago para poder adquirir los Servicios deberá ser efectuado a través de la plataforma PayPal.</p>
                    <p><strong>7.2. Liberación de Responsabilidad.</strong> El Usuario, en este acto, libera a <strong>QIBO</strong> de cualquier responsabilidad relacionada con cualquier cargo o uso inadecuado de su tarjeta de crédito o medio de pago proporcionado.</p>
                    <p><strong>7.3. Facturación.</strong> La facturación por la contratación de cualquier Servicio se encuentra sujeta a los Términos y Condiciones que para tal efecto se encuentren a disposición del Usuario en la plataforma PayPal, debiendo seguir el procedimiento que en la misma se especifique.</p>
                    <p><strong>7.4. Devoluciones y Reembolsos.</strong> El Usuario acepta y reconoce que, bajo ninguna circunstancia, <strong>QIBO</strong> estará obligado a realizar devoluciones o reembolsos de los montos pagados por la contratación de los Servicios.</p>
                    <p>No obstante lo anterior, el Usuario tendrá derecho de realizar la revocación de su consentimiento dentro de los cánones que dispone la Ley Federal de Protección al Consumidor, por lo que tendrá un plazo de 5 (cinco) días hábiles para así hacerlo, siempre y cuando la fecha de prestación del Servicio sea en un plazo superior a 10 (diez) días hábiles a partir de la fecha de contratación.</p>
                    <p><strong>7.5. Compensación o Bonificación.</strong> El Usuario podrá solicitar la compensación o bonificación, nunca la devolución o reembolso, en los términos que <strong>QIBO</strong> estime pertinentes, en caso de que: (a) se prestare al Usuario un servicio deficiente por causas directamente imputables a  <strong>QIBO</strong>; o (b) no se preste el servicio por causas directamente imputables a <strong>QIBO</strong>.</p>
                    <p><strong>7.6. Pago Previo.</strong> El Usuario autoriza expresamente a <strong>QIBO</strong> a realizar a través de PayPal el cargo o cargos aplicables por la adquisición de Tokens.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Liberación y limitación de responsabilidad</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>8.1. Información Publicada.</strong> <strong>QIBO</strong> no garantiza la exactitud, confiabilidad o integridad del contenido de la Plataforma ni de los resultados de los Servicios. El Usuario libera a <strong>QIBO</strong> de cualquier responsabilidad sobre la aptitud, confiabilidad o fiabilidad el contenido de la Plataforma.</p>
                    <p><strong>8.2. Limitación de Responsabilidad.</strong> El Usuario acepta y reconoce que <strong>QIBO</strong> no será responsable por daños de cualquier tipo, derivados del acceso del Usuario a la Plataforma, así como derivados de la prestación de los Servicios.</p>
                    <p><strong>8.3. Sitios de Terceros.</strong> La Plataforma podrá contener vínculos a sitios o aplicaciones de terceros. Estos vínculos no implican que <strong>QIBO</strong> ha aprobado su contenido. <strong>QIBO</strong> no hace ninguna declaración con respecto al contenido o la veracidad del material en los sitios o aplicaciones de dichos terceros. Si el Usuario decide ingresar a los sitios o aplicaciones de terceros, lo hace bajo su responsabilidad y riesgo.</p>
                    <p><strong>8.4. Continuidad.</strong> Con el objetivo brindar seguridad a los Usuarios en caso de presentarse una falla en la Plataforma, <strong>QIBO</strong> dará atención vía la dirección electrónica servicioaclientes@qibo.io</p>
                    <p><strong>8.5. Operación de la Plataforma.</strong> El Usuario faculta a <strong>QIBO</strong> a contratar a terceros a fin de mantener, operar y atender la Plataforma. El Usuario libera a <strong>QIBO</strong> de cualquier responsabilidad derivada de la falla o reducción operativa de la Plataforma por el involucramiento de terceros subcontratados para su operación, mantenimiento y atención.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Publicidad y promociones</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>9.1. Autorización.</strong> El Usuario autoriza que <strong>QIBO</strong> le envíe por correo electrónico boletines, contenido, comunicados y documentación publicitaria o con fines de mercadotecnia, tanto propia como de terceros.</p>
                    <p><strong>9.2. Base de Datos de Publicidad.</strong> El Usuario acepta ser incluido en la lista de destinatarios autorizados de información publicitaria enviada por <strong>QIBO</strong>, tanto propia como de terceros.</p>
                    <p><strong>9.3. Listado de Exclusión.</strong> El Usuario podrá revocar su consentimiento para recibir información publicitaria al pulsar el vínculo que se encuentra en la parte final del cuerpo de todos los correos publicitarios que envíe <strong>QIBO</strong>.</p>
                    <p><strong>9.4. Promociones.</strong> <strong>QIBO</strong> podrá ofrecer discrecionalmente promociones a los Usuarios.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Propiedad intelectual</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>10.1. Propiedad.</strong> La Plataforma y los Servicios, así como, sus entregables y contenido, que incluye -enunciativa más no limitativamente- textos, fotografías, ilustraciones, gráficos, videos, audio, diseños, códigos, datos y cualquier otra información, son propiedad única y exclusiva de <strong>QIBO</strong>. Lo anterior comprende cualesquiera derechos de autor, derechos de marca, derechos de patente, derechos de base de datos, derechos morales, y otras propiedades intelectuales y derechos patrimoniales, que se encuentran protegidos por la Ley Federal del Derecho de Autor, la Ley Federal de Protección a la Propiedad Industrial, así como cualquier otro ordenamiento en materia de propiedad intelectual. Se les advierte a los Usuarios que la totalidad del contenido de la Plataforma, así como la propia Plataforma, y los resultados, productos finales o cualesquiera obras relacionadas con los Servicios están protegidos por la legislación vigente mexicana e internacional relativa a la propiedad intelectual e industrial.</p>
                    <p><strong>10.2. Inexistencia de Transmisión de Propiedad o Licencia.</strong> El Uso de la Plataforma, así como la contratación de los Servicios no le otorga en ningún momento al Usuario propiedad del contenido de la Plataforma, ni tampoco se le otorga licencia para su explotación. El Usuario en este acto reconoce que por ningún motivo adquiere derecho alguno para descargar o grabar material con derechos de autor de la Plataforma o relacionado con los Servicios, lo cual estará estrictamente prohibido.</p>
                    <p><strong>10.3. Marcas.</strong> Los signos distintivos, incluyendo las marcas y avisos comerciales, así como demás contenido, expuestos en la Plataforma y los Servicios serán en todo momento propiedad de <strong>QIBO</strong>. En consecuencia, no podrán ser usados por el Usuario sin consentimiento expreso y por escrito de <strong>QIBO</strong>.</p>
                    <p><strong>10.4. Aviso Legal.</strong> En caso de considerar que cualquier contenido publicado en la Plataforma es violatorio de derechos de propiedad intelectual o industrial, el Usuario podrá realizar una notificación a la dirección electrónica servicioaclientes@qibo.io con el asunto “Violación de Propiedad Intelectual”. El Usuario tendrá que indicar:</p>
                    <ol className="roman">
                        <li>Datos personales de contacto: nombre, dirección, número de teléfono y dirección de correo electrónico.</li>
                        <li>Documentación que acredite su identidad.</li>
                        <li>Indicación clara, precisa y completa del (o los) contenido(s) protegido(s) supuestamente infringido(s), así como de su ubicación en la Plataforma.</li>
                        <li>Documentación que acredite la titularidad de los derechos supuestamente infringidos.</li>
                        <li>Declaración expresa y clara de que la introducción del (o los) contenido(s) indicado(s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos.</li>
                        <li>Declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del (o los) contenido(s) constituye una violación de sus derechos de propiedad intelectual.</li>
                    </ol>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Privacidad, protección de datos personales y confidencialidad</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>11.1. Propiedad.</strong> Los Datos Personales y documentos que reciba <strong>QIBO</strong> como parte de la solicitud de cualquier Servicio son propiedad exclusiva de su titular y en ninguna circunstancia serán compartidos con terceros sin que medie previa autorización de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
                    <p><strong>11.2. Confidencialidad.</strong> <strong>QIBO</strong> hace del conocimiento del Usuario, quien a su vez comprende y acepta, que toda la información personal que se obtenga del Usuario en razón del uso de la Plataforma o la provisión de los Servicios, será tratada de manera estrictamente confidencial y protegida contra cualquier uso indebido o alteración, de acuerdo con lo establecido en el Aviso de Privacidad.</p>
                    <p><strong>11.3. Provisión de Información.</strong> Para el caso de Cuentas Personales, el Usuario reconoce y acepta que <strong>QIBO</strong> obtiene su información personal por medio de la provisión o captura de forma directa a través de la Plataforma, sus funcionalidades o módulos.</p>
                    <p><strong>11.4. Aviso de Privacidad.</strong> Adicionalmente, el Usuario reconoce y consiente que, previo a recabar su información, <strong>QIBO</strong> puso a su disposición el Aviso de Privacidad correspondiente. Documento en el cual se le hizo sabedor sobre qué Datos Personales son recabados, las finalidades de su tratamiento, así como la forma, mecanismos y requisitos para el debido ejercicio de sus Derechos ARCO, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. El Usuario reconoce que en el documento de referencia se le proporcionó la información necesaria sobre los mecanismos para llevar a cabo la revocación de su consentimiento para el tratamiento de sus Datos Personales, así como los medios para limitar su uso y divulgación.</p>
                    <p><strong>11.5. Consentimiento.</strong> En su caso, el Usuario reconoce que otorga su información y Datos Personales a <strong>QIBO</strong> de manera enteramente libre e informada, utilizando su capacidad y criterio, además de que lo hace en ejercicio de su plena voluntad, libre de coacción, coerción o violencia.</p>
                    <p><strong>11.6. Veracidad de la Información.</strong> Al aceptar los presentes Términos y Condiciones, el Usuario se obliga a que toda la información que le proporcione a <strong>QIBO</strong> sea veraz y comprobable, por lo que garantiza la autenticidad de los Datos Personales proporcionados.</p>
                    <p><strong>11.7. Observación de Principios.</strong> <strong>QIBO</strong> se obliga a observar, respecto de los Datos Personales que recaba del Usuario, los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
                    <p><strong>11.8. Modificaciones al Aviso de Privacidad.</strong> El Aviso de Privacidad puede ser modificado en el tiempo y forma que <strong>QIBO</strong> lo determine, quien notificará al Usuario de dicha modificación mediante avisos en la Plataforma o el envío de correos electrónicos, a efecto de que el Usuario se encuentre en posibilidad de ejercer sus Derechos ARCO. Será responsabilidad y obligación del Usuario revisar el Aviso de Privacidad, el cual estará a su disposición, en todo momento actualizado en la Plataforma.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Modificaciones</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>12.1. Cambios.</strong> <strong>QIBO</strong> podrá -en cualquier momento y cuando lo considere conveniente- realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, Servicios y demás elementos de la Plataforma. En ningún caso ello podrá dar lugar a derecho, reclamación o indemnización alguna en favor del Usuario.</p>
                    <p><strong>12.2. Modificaciones a los Términos y Condiciones.</strong> <strong>QIBO</strong> se reserva el derecho de modificar los presentes Términos y Condiciones en cualquier tiempo, siendo estas enmiendas efectivas de forma inmediata una vez consentidas por el Usuario o transcurrido el plazo establecido para su entrada en vigor.</p>
                    <p><strong>QIBO</strong> hará del conocimiento de los Usuarios las reformas con una anticipación de 30 (treinta) días naturales a su entrada en vigor, ya sea mediante la publicación de un aviso en a la Plataforma respecto a la modificación de los Términos y Condiciones o mediante una notificación vía correo electrónico.</p>
                    <p><strong>12.3. Revisiones.</strong> El Usuario manifiesta su aceptación y comprende la responsabilidad de realizar revisiones periódicas de las notificaciones enviadas por <strong>QIBO</strong>, con objeto de mantenerse al tanto de las modificaciones, adiciones, adecuaciones, actualizaciones o supresiones a los presentes Términos y Condiciones. En cada ocasión que el Usuario marque la casilla correspondiente a la aceptación de este contrato se entenderá que expresa su aceptación absoluta a las reformas que en su caso se realicen sobre los presentes Términos y Condiciones.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Vigencia</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>13.1. Plazo.</strong> <strong>QIBO</strong> y el Usuario reconocen que los Términos y Condiciones son de vigencia indefinida y que entrarán en vigor a partir de que sean aceptados por el Usuario <strong>QIBO</strong> se reserva el derecho, previa notificación en la medida de lo humana y razonablemente posible al Usuario, para:</p>
                    <ol className="roman">
                        <li>Terminar definitivamente el presente contrato sin necesidad de declaración judicial.</li>
                        <li>Descontinuar la Plataforma.</li>
                    </ol>
                    <p><strong>13.2. Suspensión.</strong> <strong>QIBO</strong> podrá en cualquier momento suspender el acceso a la Plataforma.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Notificaciones, aclaraciones y quejas</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>114.1. Avisos.</strong> Para efectos de los presentes Términos y Condiciones, las Partes convienen en que todas las notificaciones, aclaraciones, dudas y quejas dirigidas a <strong>QIBO</strong> deberán realizarse a través de los siguientes medios:</p>
                    <ol className="roman">
                        <li>Domicilio: Av. Jesús del Monte, número 32 interior 701, Colonia Jesús del Monte, Huixquilucan, Estado de México, C.P. 52764.</li>
                        <li>Correo electrónico: servicioaclientes@qibo.io</li>
                    </ol>
                    <p><strong>114.2. Ayuda.</strong> Adicionalmente, <strong>QIBO</strong> ofrece a los Usuarios la posibilidad de formular dudas y sugerencias vía correo electrónico a la dirección mencionada en el punto anterior.</p>
                </div>
                <div className="column c12 TermsPage__section--title">
                    <h3>Miscelánea</h3>
                </div>
                <div className="column c11 TermsPage__section--text">
                    <p><strong>15.1. Subsistencia.</strong> En caso de que alguna cláusula de los presentes Términos y Condiciones sea declarada como inválida, será eliminada o modificada y el resto de las cláusulas subsistirán.</p>
                    <p><strong>15.2. Cesión de Derechos.</strong> <strong>QIBO</strong> podrá ceder total o parcialmente sus derechos u obligaciones a su cargo sin autorización previa del Usuario, siempre en arreglo a la normatividad aplicable. En virtud de dicha cesión, <strong>QIBO</strong> quedará liberada de cualquier obligación a favor del Usuario.</p>
                    <p><strong>15.3. No Renuncia.</strong> La falta de ejercicio de <strong>QIBO</strong> de cualquier derecho o acción derivados del presente, en ningún momento deberá interpretarse como renuncia a sus derechos o acciones.</p>
                    <p><strong>15.4. Indemnización.</strong> El Usuario, en este acto, se obliga a indemnizar a <strong>QIBO</strong>, sus accionistas, partes relacionadas, proveedores y asesores por cualquier acción, demanda o reclamación, inclusive honorarios de abogados, derivados de cualquier incumplimiento por su parte a los presentes Términos y Condiciones.</p>
                    <p>En el evento de que el Usuario incumpliere con las obligaciones a las que se sujeta en razón de los presentes Términos y Condiciones, pagará a <strong>QIBO</strong> los daños y perjuicios determinados por la autoridad competente que tal incumplimiento le ocasione, sin perjuicio de las demás acciones legales que procedan, según sea la naturaleza de la obligación incumplida.</p>
                    <p><strong>15.5. Encabezados.</strong> Los encabezados de las cláusulas son incorporados por conveniencia y referencia, por lo que de ninguna manera se considerarán para efectos de su interpretación, ni afectarán las obligaciones contenidas en cada una de ellas.</p>
                    <p><strong>15.6. Legislación Aplicable.</strong> Los presentes Términos y Condiciones se regirán e interpretarán a la luz del Código de Comercio, el Código Civil Federal, la Ley Federal de Protección al Consumidor, la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el Código Penal Federal, sus respectivas disposiciones secundarias y demás legislación mexicana aplicable.</p>
                    <p><strong>15.7. Jurisdicción.</strong> Para la resolución de cualquier disputa, controversia, reclamación o diferencia que surja de o se relacione con los presentes Términos y Condiciones, el Usuario y <strong>QIBO</strong> se someten expresamente a la competencia de los órganos jurisdiccionales establecidos en la Ciudad de México, renunciando a cualquier otro fuero presente o futuro que pudiera corresponderle en virtud de sus domicilios presentes o futuros o por cualquier otra causa.</p>
                </div>
            </div>
        </div>

        <Footer />

    </>)
}

export default TermsPage
