import React from 'react'
import './RadioList.scss'

const RadioList = ({ activeValue, items, handleUpdate }) => {


    const handleClick = itemValue => {
        let newActives = [...activeValue]
        if (!newActives.includes(itemValue)) {
            newActives.push(itemValue)
        }
        else {
            for (let [idx, i] of activeValue.entries()) {
                if (i === itemValue) {
                    newActives.splice(idx, 1)
                    break
                }
            }
        }
        handleUpdate(newActives)
    }


    return (
        <div className="row c12 RadioList__container p00">
            {items.map( (i, idx) =>
                <span
                    className={`RadioList__item ${activeValue.includes(i) ? 'active' : null}`}
                    onClick={() => handleClick(i)}
                    key={idx}
                >
                    {i.text}
                </span>
            )}
        </div>
    )
}

export default RadioList
