import React, { useEffect, useState } from 'react'
import './HelpCenterPage.scss'

import BugForm from '../../forms/BugForm'

import faqs from '../../assets/json/faqs.json'

import ExpandableRow from '../../components/ExpandableRow'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HelpSearch from '../../components/HelpSearch'

import { ReactComponent as IqonMessages } from '../../assets/iqons/iqon_message-bubbles.svg'


const HelpCenterPage = () => {

    const [activeSection, setActiveSection] = useState('faq')
    const [filteredFaqs, setFilteredFaqs] = useState(null)

    const handleQuery = query => {
        if(query[0]) {
            let newFaqs = []
            faqs.forEach( i => {
                let match = 0
                for (let k of i.keywords) {
                    if (query.includes(k)) match++
                }
                if (match > 0) newFaqs.push({...i, match: match})
            })
            newFaqs.sort( (a, b) => b.match - a.match)
            setFilteredFaqs(newFaqs)
        }
        else {
            const newFaqs = [...faqs].splice(0, 5)
            setFilteredFaqs(newFaqs)
        }
    } 


    return (<>

        <Header />

        <div className="row container HelpCenterPage__container">
            <div className="column c12 HelpCenterPage__title my30 aiCenter">
                <h1><span className="txt--accent">Ayudarte</span> es lo nuestro</h1>
                <div className="row c06m c08s HelpCenterPage__title--search p00">
                    <HelpSearch update={handleQuery}/>
                </div>
            </div>
            {activeSection === 'faq' && <div className="column c12 HelpCenterPage__faq mb30">
                <h2 className="HelpCenterPage__section-title">Preguntas frecuentes</h2>
                {filteredFaqs && filteredFaqs.map( (i, idx) =>
                    <ExpandableRow
                        question={i.question}
                        answer={i.answer}
                        key={idx}
                    />
                )}
            </div>}
            <div className="column c12 HelpCenterPage__expert mb100 aCenter">
                <p>¿No encontraste lo que buscabas? Platica con un experto</p>
                <div className="row c06m c08s pt30">
                    <BugForm />
                </div>
            </div>
        </div>

        <Footer />

    </>)
}

export default HelpCenterPage
