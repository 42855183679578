import React, { createContext, useContext, useEffect, useState } from 'react'
import useWindowSize from './hooks/useWindowSize'


const AppHeightContext = createContext()
const AppWidthContext = createContext()


const Provider = ( { children } ) => {

    const { height, width } = useWindowSize()
    const [vw, setVW] = useState(width)
    const [vh, setVH] = useState(height)

    useEffect( () => {
        setVW(width)
    }, [width])

    useEffect( () => {
        setVH(height)
    }, [height])


    return (
        <AppWidthContext.Provider value={vw}>
        <AppHeightContext.Provider value={vh}>

        {children}

        </AppHeightContext.Provider>
        </AppWidthContext.Provider>
    )
}

export default Provider


export const useAppWidth = () => useContext(AppWidthContext)
export const useAppHeight = () => useContext(AppHeightContext)