import React from 'react'
import { useAppWidth } from '../../Provider'
import './BizPage.scss'

import Button from '../../components/Button'
import Footer from '../../components/Footer'
import FooterCta from '../../components/FooterCta'
import Header from '../../components/Header'
import PricingCard from '../../components/PricingCard'
import Slot from '../../components/Slot'

// import imgLevel from '../../assets/images/image_computer-levels.svg'
import imgLevel from '../../assets/images/image_level-risks.svg'
import imgPeople from '../../assets/images/image_people-risks.svg'
import imgTopics from '../../assets/images/image_topic-risks.svg'
import { ReactComponent as IqonCalendar} from '../../assets/iqons/iqon_calendar.svg'
import { ReactComponent as IqonClock} from '../../assets/iqons/iqon_clock.svg'
import { ReactComponent as IqonUsers} from '../../assets/iqons/iqon_dot-triangle.svg'
import { ReactComponent as IqonGlobe} from '../../assets/iqons/iqon_globe.svg'
import { ReactComponent as IqonGraph} from '../../assets/iqons/iqon_graph.svg'
import { ReactComponent as IqonLightbulb} from '../../assets/iqons/iqon_lightbulb.svg'
import { ReactComponent as IqonPerson} from '../../assets/iqons/iqon_person.svg'
import { ReactComponent as IqonSearch} from '../../assets/iqons/iqon_search.svg'
import { ReactComponent as IqonTag} from '../../assets/iqons/iqon_tag.svg'
import { ReactComponent as IqonTest} from '../../assets/iqons/iqon_test.svg'


const BizPage = () => {

    const vw = useAppWidth()


    return (<>

        <Header />

        <div className="container fullHeight aCenter">
            <div className="column c12 BizPage__title mt30 aCenter">
                <p className="p--identifier center shade">Empresarial y seguridad</p>
                <h1>Conviértete en el<br /><span className="txt--accent">héroe</span> de la empresa</h1>
                <p className="BizPage__title--subtitle">Encuentra los riesgos que los demás no saben que existen en la empresa</p>
            </div>
            <div className={`c12 BizPage__cta ${vw >= 768 ? 'row jcCenter' : 'column'}`}>
                <Button
                    css="btn btn--grow p20"
                    action="/contact"
                >
                    Contáctanos, somos expertos
                </Button>
            </div>
            <div className="row c12 BizPage__percentages px00 my50">
                <div className="row c04s BizPage__percentages--item">
                    <div className="column c12">
                        <p className="BizPage__percentages--title">46<span>%</span></p>
                        <p className="BizPage__percentages--description">De los colaboradores representan un riesgo para la empresa</p>
                    </div>
                </div>
                <div className="row c04s BizPage__percentages--item">
                    <div className="column c12">
                        <p className="BizPage__percentages--title">13<span>%</span></p>
                        <p className="BizPage__percentages--description">Han cometido o están cometiendo actos contra la empresa</p>
                    </div>
                </div>
                <div className="row c04s BizPage__percentages--item">
                    <div className="column c12">
                        <p className="BizPage__percentages--title">33<span>%</span></p>
                        <p className="BizPage__percentages--description">Saben quién está cometiendo actos contra la empresa, pero no lo reportan</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="container my100">
            <div className="column c12 BizPage__moments--title aCenter">
                <p className="p--identifier center primary">Momentos</p>
                <h3 className="mt00 taCenter">Para qué usar Qibo<sup>®</sup></h3>
            </div>
            <div className={`row c12 BizPage__moments--items ${vw < 768 ? 'p00' : undefined}`}>
                <div className="row c03l c06s">
                    <Slot
                        Iqon={IqonPerson}
                        title="Reclutamiento"
                        titleHeight={vw > 1200 ? 2.3 : 1}
                    >
                        Prevenir es muy sencillo, siempre que lo hagas desde el inicio de la relación laboral.

                    </Slot>
                </div>
                <div className="row c03l c06s">
                    <Slot
                        Iqon={IqonCalendar}
                        title="Prevención periódica"
                        titleHeight={vw > 1200 ? 2.3 : 1}
                    >
                        Lo que no se conoce, no se previene. Evalúa a los colaboradores para saber quién te representa un riesgo.

                    </Slot>
                </div>
                <div className="row c03l c06s">
                    <Slot
                        Iqon={IqonSearch}
                        title="Investigación"
                        titleHeight={vw > 1200 ? 2.3 : 1}
                    >
                        Detecta a las personas que ya estuvieron involucradas en un evento específico.

                    </Slot>
                </div>
                <div className="row c03l c06s">
                    <Slot
                        Iqon={IqonLightbulb}
                        title="Cuando más lo necesites"
                        titleHeight={vw >= 1200 ? 2.3 : 1}
                    >
                        Usa Qibo<sup>®</sup> en cada momento que necesites conocer la intención real o falta de veracidad de la otra persona.
                    </Slot>
                </div>
            </div>
        </div>

        <div className="container BizPage__features">
            <div className="row c12 BizPage__feature">
                <div className="column c05s BizPage__features--info">
                    <p className="p--identifier left primary">Funciones</p>
                    <h3 className="mt00">Vas a conocer el riesgo que corre la empresa</h3>
                    <p className="p--description">Cuando empieces a usar nuestras soluciones, conocerás qué porcentaje de tus colaboradores representan un riesgo alto y cuál representa un riesgo medio. Tendrás una visión más real y certera de en dónde estás parado.</p>
                </div>
                <div className="row c07s BizPage__features--risk-level">
                    <img src={imgLevel} alt="Temas de riesgo de la empresa" />
                </div>
            </div>
            <div className="row c12 BizPage__feature">
                <div className="row c07s BizPage__features--risk-topics">
                    <img src={imgTopics} alt="Temas de riesgo de la empresa" />
                </div>
                <div className="column c05s BizPage__features--info">
                    <h3 className="mt00">También, los temas de más riesgo en la empresa</h3>
                    <p className="p--description">Conocer esta información te ayudará a enfocar tus esfuerzos de prevención en los temas verdaderamente relevantes. Tu empresa podría estar corriendo un riesgo de daño severo sobre algún tema que ni siquiera tenías en el radar.</p>
                </div>
            </div>
            <div className="row c12 BizPage__feature">
                <div className="column c05s BizPage__features--info">
                    <h3 className="mt00">Y hasta qué personas representan un riesgo</h3>
                    <p className="p--description">Únicamnte hace falta que una sola persona se decida a cometer un acto en contra de la empresa para causar un daño irreparable. ¿Para qué esperar a que algo malo suceda, sabiendo que se pudo haber evitado a tiempo?</p>
                </div>
                <div className="row c07s BizPage__features--risk-people">
                    <img src={imgPeople} alt="Temas de riesgo de la empresa" />
                </div>
            </div>
        </div>

        <div className="container my100">
            <div className="column c12 BizPage__more-features--title aCenter">
                <p className="p--identifier center primary">Más funciones</p>
            </div>
            <div className={`row c12 BizPage__more-features--items ${vw < 768 ? 'p00' : undefined}`}>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonGraph}
                        title="Análisis de datos"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Realiza análisis cuantitativos de los riesgos presentes en tu empresa
                    </Slot>
                </div>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonTest}
                        title="Cuestionarios personalizados"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Somos sastres de la prevención. Realizamos los cuestionarios para las entrevistas a la medida de cada empresa; por puesto y momento.
                    </Slot>
                </div>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonClock}
                        title="Disponible 24/7"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        La entrevistas se pueden realizar a cualquier hora y cualquier día de la semana.
                    </Slot>
                </div>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonTag}
                        title="Organización y filtrado"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Encuentra la información que requieres de una forma rápida y organizada.
                    </Slot>
                </div>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonUsers}
                        title="Multiusuario"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Utiliza los diferentes perfiles de usuario para que cada quién tenga acceso únicamente a la información a la que tiene autorización visualizar.
                    </Slot>
                </div>
                <div className="row c04m c06s">
                    <Slot
                        Iqon={IqonGlobe}
                        title="Desde cualquier parte del mundo"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Las entrevistas se pueden realizar desde cualquier lugar del mundo que tenga acceso a una línea telefónica fija o celular.
                    </Slot>
                </div>
            </div>
        </div>

        <FooterCta btnText="Contáctanos, somos expertos" action="/contact" />

        {/* <div className="container my100">
            <div className="column c12 BizPage__pricing--title aCenter">
                <p className="p--identifier center primary">Inversión</p>
                <h3 className="mt00">Prevenir es más económico que una comida</h3>
            </div>
            <div className="row c12 BizPage__pricing--plans">
                <div className="row c04">
                    <PricingCard
                        title="Básica"
                        amount="$175.00"
                        measure="por análisis"
                        priceLegal
                        features={[
                            'Compra mínima: <strong>50 análisis</strong>',
                            'Analiza conductas de riesgo',
                            'Ideal para procesos masivos o puestos de alta rotación'
                        ]}
                    />
                </div>
                <div className="row c04">
                    <PricingCard
                        title="Profunda"
                        amount="$450.00"
                        measure="por análisis"
                        priceLegal
                        features={[
                            'Compra mínima: <strong>20 análisis</strong>',
                            'Análiza conductas',
                            'Analiza temas de riesgo',
                            'Ideal para puestos sensibles o procesos específicos'
                        ]}
                    />
                </div>
                <div className="row c04">
                    <PricingCard
                        title="Por volumen"
                        amount="Pregunta a ventas"
                        features={[
                            'Ideal para conocer los riesgos de tu empresa'
                        ]}
                        cta={{name: 'Contactar a ventas', to: '/contact'}}
                        special
                    />
                </div>
                <div className="row c12 pt00">
                    <p className="legal">* Los precios de inversión son en pesos mexicanos, más el respectivo impuesto al valor valor agregado (IVA). Todos los precios están sujetos a cambio sin previo aviso.</p>
                </div>
            </div>
        </div> */}

        <Footer />

    </>)
}

export default BizPage
