import React, { useState } from 'react'
import './DoughnutGraph.scss'


const DoughnutGraph = ({ color, size, space,  stroke,  percentage, textSize }) => {

    const [spaceWidth] = useState(space || 0)


    return (
        <div className="row DoughnutGraph__container p00 jcCenter">
            <svg
                className="DoughnutGraph__bg"
                style={{
                    width: `${(size * 2) + stroke + spaceWidth}px`,
                    height: `${(size * 2) + stroke + spaceWidth}px`,
                    strokeWidth: `${stroke + spaceWidth}px`
                }}
            >
                <circle cx={size + ((stroke + spaceWidth) / 2)} cy={size + ((stroke + spaceWidth) / 2)} r={size}/>
            </svg>
            <svg
                className={`DoughnutGraph__fg ${color}`}
                style={{
                    width: `${(size * 2) + stroke + spaceWidth}px`,
                    height: `${(size * 2) + stroke + spaceWidth}px`,
                    strokeWidth: `${stroke}px`,
                    strokeDasharray: `${3.141593 * size * 2 * (percentage / 100)}, ${3.141593 * size * 2}`
                }}
            >
                <circle cx={size + ((stroke + spaceWidth) / 2)} cy={size + ((stroke + spaceWidth) / 2)} r={size}/>
            </svg>
            <div className="DoughnutGraph__number">
                <span style={{fontSize: `${textSize || 30}pt`}}>{percentage}</span>
                <span style={{fontSize: `${(textSize / 2) || 15}pt`}}>%</span>
            </div>
        </div>
    )
}

export default DoughnutGraph
