import React, { useEffect, useState } from 'react'
import './HelpSearch.scss'


const HelpSearch = ({ update }) => {

    const [inputStyle] = useState({
        fontSize: '13pt',
        borderRadius: '100px',
        padding: '25px'
    })
    const [query, setQuery] = useState('')

    useEffect( () => {
        const delay = setTimeout( () => update(query.trim().toLocaleLowerCase().split(' ')), 500)
        return () => clearTimeout(delay)
    }, [query])

    
    return (
        <div className="row c12 HelpSearch__container jcCenter">
            <input
                className={`HelpSearch__input ${query && 'active'}`}
                type="search"
                style={inputStyle}
                onChange={ e => setQuery(e.target.value)}
                placeholder="Encuentra la respuesta a tu duda..."
            />
        </div>
    )
}

export default HelpSearch
