
const salesHome_valid = (values) => {
    let errors = {}
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// First name validation
    if (!values.first_name) errors.first_name = 'Por favor escribe tu nombre'
    else if (values.first_name.trim().length < 2) errors.first_name = 'El nombre que escribiste es muy corto'

// Last name validation
    if (!values.last_name) errors.last_name = 'Por favor escribe tu apellido'
    else if (values.last_name.trim().length < 2) errors.last_name = 'El apellido que escribiste es muy corto'

// Phone number validation
    if (values.contact_channel === 'call' && !values.phone_number) errors.phone_number = 'Por favor escribe tu número de teléfono o celular'
    else if (values.contact_channel === 'whatsapp' && !values.phone_number) errors.phone_number = 'Por favor escribe tu número de celular'
    else if (values.contact_channel === 'call' && values.phone_number.trim().length < 10) errors.phone_number = 'El número de teléfono o celular que escribiste es muy corto'
    else if (values.contact_channel === 'whatsapp' && values.phone_number.trim().length < 10) errors.phone_number = 'El número de celular que escribiste es muy corto'

// Email validation
    if (values.contact_channel === 'email' && !values.email) errors.email = 'Por favor escribe tu email'
    else if (values.contact_channel === 'email' && !emailRegex.test(values.email)) errors.email = 'Por favor escribe una dirección de email válida'

// Country validation
    if (!values.country) errors.country = 'Por favor escribe el país donde se encuentra la empresa'
    else if (values.country.trim().length < 2) errors.country = 'El nombre del país que escribiste es muy corto'

// Sales interests validation
    if (values.sales_interests.length === 0) errors.sales_interests = 'Debes elegir al menos 1 tema de interés'

    return errors
}

export default salesHome_valid