import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


// * Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsdf__DuH_Q-yxO5Dlpy5jUiFYoRmL0qs",
  authDomain: "qibo-site.firebaseapp.com",
  projectId: "qibo-site",
  storageBucket: "qibo-site.appspot.com",
  messagingSenderId: "553150678521",
  appId: "1:553150678521:web:5f6abdaaf16f109cf5e928",
  measurementId: "G-NL9CZNVGPS"
};

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig)
const db = fb.firestore()
const fv = firebase.firestore.FieldValue
const auth = firebase.auth()
const authPersistence = firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
const googleProvider = new firebase.auth.GoogleAuthProvider()

export { auth, authPersistence, db, fv, googleProvider }