import React, { useEffect, useState } from 'react'
import './BugForm.scss'

import useMailing from '../../hooks/useMailing'

import bug_valid from '../../hooks/useValid/bug_valid'
import useValid from '../../hooks/useValid'

import Button from '../../components/Button'
import DeliveryStatus from '../../components/DeliveryStatus'
import Select from '../../components/Select'

import { ReactComponent as IqonDanger } from '../../assets/iqons/iqon_danger_stroke.svg'


const BugForm = () => {

    const [initialState] = useState({
        business_name: '',
        category: '',
        category_text: '',
        description: '',
        email: '',
        first_name: '',
        last_name: '',
        subject: '',
    })
    const [category] = useState([
        {
            text: 'Inicio de sesión',
            value: 'login'
        },
        {
            text: 'Creación de una cuenta personal',
            value: 'signup'
        },
        {
            text: 'Análisis de entrevistas',
            value: 'analysis'
        },
        {
            text: 'Añadir/eliminar candidatos',
            value: 'candidates'
        },
        {
            text: 'Asignación/reasignación de cuestionarios',
            value: 'tests'
        },
        {
            text: 'Pago en línea',
            value: 'payments'
        },
        {
            text: 'Administración de etiquetas',
            value: 'tags'
        },
        {
            text: 'Agregar/eliminar de usuarios',
            value: 'users'
        },
        {
            text: 'Filtros de consulta',
            value: 'filters'
        },
        {
            text: 'Otro',
            value: 'other'
        }
    ])

    const [delivery, setDelivery] = useState(null)

    const { formData, forceTouch, handleBlur, handleChange } = useValid(initialState, bug_valid)
    const { contactBugOps } = useMailing()

    const input_data = formData.input_data
    const errors = formData.errors_data
    const touched = formData.touched_fields
    const valid = formData.validation.may_submit

    const delivered = delivery && delivery.delivery && delivery.delivery.state === 'SUCCESS'
    const undelivered = delivery && delivery.delivery && delivery.delivery.state === 'ERROR'

    const handleChannelPlaceholder = () => {
        if (input_data.contact_channel === 'call') return 'Teléfono o celular *'
        else if (input_data.contact_channel === 'email') return 'Email *'
        else if (input_data.contact_channel === 'whatsapp') return 'Celular *'
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!valid) {
            forceTouch('category')
            forceTouch('description')
            forceTouch('email')
            forceTouch('first_name')
            forceTouch('last_name')
            forceTouch('subject')
        }
        else if (valid) {
            setDelivery(true)
            contactBugOps(
                input_data.category_text,
                input_data.description,
                input_data.email,
                input_data.first_name,
                input_data.last_name,
                input_data.subject,
            ).then( r => {
                r.onSnapshot( snapshot => {
                    console.log('mail', snapshot.data())
                    setDelivery(snapshot.data())
                })
            })
        }
    }

    const formRender = () =>
        <form onSubmit={handleSubmit} className="row c12 BugForm__container p00">
            <div className="row c06 BugForm__field--container py10">
                <input
                    className={`
                        ${!errors.first_name && touched.first_name && 'success'}
                        ${errors.first_name && touched.first_name && 'error'}
                    `}
                    name="first_name"
                    type="text"
                    placeholder="Nombre *"
                    value={input_data.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.first_name && touched.first_name && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.first_name}
                </span>}
            </div>
            <div className="row c06 BugForm__field--container py10">
                <input
                    className={`
                        ${!errors.last_name && touched.last_name && 'success'}
                        ${errors.last_name && touched.last_name && 'error'}
                    `}
                    name="last_name"
                    type="text"
                    placeholder="Apellido *"
                    value={input_data.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.last_name && touched.last_name && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.last_name}
                </span>}
            </div>
            <div className="row c12 BugForm__field--container py10">
                <input
                    className={`
                        ${!errors.email && touched.email && 'success'}
                        ${errors.email && touched.email && 'error'}
                    `}
                    name="email"
                    type="email"
                    placeholder="Email de usuario en Qibo® App *"
                    value={input_data.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.email && touched.email && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.email}
                </span>}
            </div>
            <div className="row c12 BugForm__field--container py10">
                <Select
                    activeValue={input_data.category_text}
                    placeOption="Categoría *"
                    height="350px"
                    success={!errors.category && touched.category}
                    error={errors.category && touched.category}
                    slice={50}
                >
                    {category.map( (i, idx) =>
                        <span
                            className={`${input_data.category === i.value ? 'active' : null}`}
                            inside="true"
                            key={idx}
                            onClick={() => {
                                handleChange({target: {
                                    name: 'category',
                                    value: i.value
                                }})
                                handleChange({target: {
                                    name: 'category_text',
                                    value: i.text
                                }})
                                setTimeout( () => {
                                    forceTouch('role')
                                }, 50)
                            }}
                        >
                            {i.text}
                        </span>
                    )}
                </Select>
                {errors.category && touched.category && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.category}
                </span>}
            </div>
            <div className="row c12 BugForm__field--container py10">
                <input
                    className={`
                        ${!errors.subject && touched.subject && 'success'}
                        ${errors.subject && touched.subject && 'error'}
                    `}
                    name="subject"
                    type="text"
                    placeholder="Resumen *"
                    value={input_data.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.subject && touched.subject && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.subject}
                </span>}
            </div>
            <div className="row c12 BugForm__field--container py10">
                <textarea
                    className={`
                        ${!errors.description && touched.description && 'success'}
                        ${errors.description && touched.description && 'error'}
                    `}
                    name="description"
                    rows="3"
                    placeholder="Descripción detallada *"
                    value={input_data.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.description && touched.description && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.description}
                </span>}
            </div>
            <div className="row c12 BugForm__field--container py10">
                <Button
                    css={`btn ${valid ? 'btn--grow' : 'btn--disabled'} mlAuto`}
                    inlineStyle={{fontWeight: 800}}
                    type="submit"
                >
                    Enviar
                </Button>
            </div>
        </form>

    return (<>
        {!delivery ? formRender() : <DeliveryStatus error={undelivered} success={delivered} />}
    </>)
}

export default BugForm
