import React from 'react'
import './Multibutton.scss'

const Multibutton = ( {children} ) => {
    

    return (
        <div className="Multibutton__scroll">
            <div className="Multibutton__bg">
                {children}
            </div>
        </div>
    )
}

export default Multibutton
