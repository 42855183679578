import React, { useEffect, useState } from 'react'
import { useAppWidth } from '../../Provider'
import './PressPage.scss'

import { db } from '../../firebase-config'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import NewsCard from '../../components/NewsCard'
import NewsRow from '../../components/NewsRow'

import imgTopNews01 from '../../assets/images/photo_business-insider.jpg'


const PressPage = () => {

    const vw = useAppWidth()

    const [news, setNews] = useState(null)
    const [topNews, setTopNews] = useState(null)

    useEffect( () => {        
        db.collection('news')
            .get()
            .then( snapshot => {
                if (snapshot.empty) return
                const newTopNews = []
                const newNews = snapshot.docs.map( i => {
                    if (i.data().top_news) newTopNews.push(i.data())
                    return i.data()
                })
                newTopNews.sort( (a, b) => a.top_order - b.top_order)
                newNews.sort( (a, b) => b.publication_date.seconds - a.publication_date.seconds)
                setTopNews(newTopNews)
                setNews(newNews)
            })
    }, [])


    return (<>

        <Header />

        <div className="container PressPage__conatiner">

            <div className="column c12 PressPage__title aCenter mb40">
                <h1 className="mb20">Sala de <span className="txt--accent">prensa</span></h1>
                <p className="m00 taCenter">Conoce por qué los medios están hablando de nosotros</p>
                {/* <div className="row c12 PressPage__link-bar jcCenter mt40">
                    <Link className="PressPage__link-bar--link" to="/">En los medios</Link>
                    <span className="mx20">|</span>
                    <Link className="PressPage__link-bar--link" to="/">Comunicados de prensa</Link>
                </div> */}
            </div>
        </div>

        <div className={`PressPage__conatiner ${vw >= 992 ? 'container' : 'c12 p00'}`}>
            {topNews && <div className="row c12 PressPage__news-top">
                <div className="row PressPage__news-top--container pr20">
                    {topNews.map( (i, idx) =>
                        <NewsCard data={i} key={idx} />
                    )}
                </div>
            </div>}
        </div>

        <div className="container PressPage__conatiner">
            <div className="column c12 PressPage__news-archive mb150">
                <h3>Todos los artículos y noticias</h3>
                {news && news.map( (i, idx) =>
                    <NewsRow data={i} first={idx === 0} key={idx} />
                )}
            </div>
        </div>

        <Footer />

    </>)
}

export default PressPage
