import React, { useState } from 'react'
import { useAppWidth } from '../../Provider'
import './HomePage.scss'

import Button from '../../components/Button'
import EfficacyCard from '../../components/EfficacyCard'
import Footer from '../../components/Footer'
import FooterCta from '../../components/FooterCta'
import Header from '../../components/Header'
import RatingCard from '../../components/RatingCard'
import Slot from '../../components/Slot'

import imgPersonRisks from '../../assets/images/image_person-risks.png'
import { ReactComponent as LogoBusinessInsider} from '../../assets/images/logo_business-insider.svg'
import { ReactComponent as LogoFinanciero} from '../../assets/images/logo_financiero.svg'
import { ReactComponent as LogoMSN} from '../../assets/images/logo_msn.svg'
import { ReactComponent as LogoMVS} from '../../assets/images/logo_mvs-noticias.svg'
import { ReactComponent as LogoReforma} from '../../assets/images/logo_reforma.svg'


const HomePage = () => {

    const vw = useAppWidth()

    const [technologies] = useState([
        {
            name: 'Polígrafo',
            color: 'black',
            percentage: 70,
            params: {
                stress: true,
                objectiveness: false
            }
        },
        {
            name: 'Pruebas de integridad laboral',
            color: 'black',
            percentage: 45,
            params: {
                statistics: true,
                objectiveness: true
            }
        },
        {
            name: 'Test de personalidad',
            color: 'black',
            percentage: 38,
            params: {
                statistics: true,
                objectiveness: true
            }
        },
        {
            name: 'Visita domiciliaria',
            color: 'black',
            percentage: 30,
            params: {
                perception: true,
                objectiveness: false
            }
        },
        {
            name: 'Referencias laborales',
            color: 'black',
            percentage: 24,
            params: {
                perception: true,
                objectiveness: false
            }
        },
        {
            name: 'Entrevista persona a persona',
            color: 'black',
            percentage: 14,
            params: {
                perception: true,
                objectiveness: false
            }
        },
        {
            name: 'Antecedentes penales',
            color: 'black',
            percentage: 12,
            params: {
                records: true,
                objectiveness: true
            }
        }
    ])

    const [activeTechnology, setActiveTechnology] = useState(technologies[1])


    return (<>

        <Header />

        <div className="row c12 HomePage__container p00">

            <div className="row c12 p00">
                <div className={`column c05s HomePage__title--text jcCenter aFlex-start
                    ${vw >= 768 ? 'fullHeight pl80' : 'p30'}
                `}>
                    {/* <h1 className="m00">Prevengamos un riesgo <span className="txt--accent">REAL</span></h1> */}
                    <h1 className="m00">Los <span className="txt--accent">riesgos</span> tienen nombre y apellido</h1>
                    <p className="my50">Con solo una entrevista telefónica, detectamos a las personas de riesgos que pueden dañar a tu familia, inmuebles, empresa, clientes, accionistas o a las utilidades</p>
                    <div className={`c12 HomePage__title--btn p00
                        ${vw >= 768 ? 'row' : 'column aCenter'}
                    `}>
                        <Button css="btn btn--primary btn--grow" action="/contact">Contáctanos, somos expertos</Button>
                    </div>
                </div>
                <div className="row c07s HomePage__title--image pb00 aCenter jcCenter">
                    <img src={imgPersonRisks} alt="Los riesgos tienen nombre" />
                </div>
            </div>

            <div className="column c12 HomePage__media py50">
                {/* <div className="row container HomePage__media--title pt10 pb30 jcCenter">
                    <p>ELLOS ESTÁN HABLANDO DE NOSOTROS</p>
                </div> */}
                <div className="row container HomePage__media--logos jcSpace-between aCenter">
                    <LogoBusinessInsider className="darker" />
                    <LogoFinanciero className="darker" style={{width: '200px'}} />
                    <LogoMVS />
                    <LogoReforma />
                    <LogoMSN style={{width: '100px'}} />
                </div>
            </div>

            {/* <div className="row container HomePage__ratings my100">
                <div className="c04s HomePage__ratings--card-container">
                    <RatingCard
                        rating={4}
                        quote="Alias qui officia eaque esse ad quidem temporibus, voluptates distinctio odit! Doloremque."
                        name="Maritza Peña"
                        // jobPosition="Analista de desarrollo organizacional"
                        company="Montepío"
                        center={vw < 768}
                    />
                </div>
                <div className="c04s HomePage__ratings--card-container">
                    <RatingCard
                        rating={5}
                        quote="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque ipsa accusantium sit ducimus autem alias quas optio aliquam distinctio corporis cupiditate."
                        name="José Ramírez"
                        // jobPosition="Seguridad"
                        company="DHL México"
                        center={vw < 768}
                    />
                </div>
                <div className="c04s HomePage__ratings--card-container">
                    <RatingCard
                        rating={4}
                        quote="Ipsum dolor sit amet consectetur adipisicing elit. Porro quos beatae facere et repellendus vel commodi ipsa quaerat."
                        name="Daniel Morales"
                        // jobPosition="Seguridad"
                        company="Protectio"
                        center={vw < 768}
                    />
                </div>
            </div> */}

            <div className="container">
                <div className="row c12 HomePage__efficacy py100">
                    <div className="row c07s HomePage__efficacy--graphs aCenter jcCenter">
                        <div className="row c12 HomePage__efficacy--graphs-container p00">
                            <div className="row c06 pr05">
                                <EfficacyCard data={{
                                    name: 'Qibo',
                                    color: 'blue',
                                    percentage: 95,
                                    params: {
                                        emotions: true,
                                        stress: true,
                                        cognitives: true,
                                        objectiveness: true
                                    }
                                }} />                     
                            </div>
                            <div className="row c06 pl05">
                                <EfficacyCard data={activeTechnology} />                     
                            </div>
                            <span className="row c12 HomePage__efficacy--vs">VS</span>
                        </div>
                    </div>
                    <div className="column c05s HomePage__efficacy--info">
                        <p className="p--identifier left primary">Certero</p>
                        <h3 className="mt00">Por qué es mejor usar Qibo<sup>®</sup></h3>
                        <p className="p--description">Qibo<sup>®</sup> utiliza la tecnología israelí más avanzada para analizar las declaraciones de <strong className="font--black">voz</strong> de los entrevistados, detectando la falta de veracidad, con hasta 95 % de certeza</p>
                        <div className="column c12 HomePage__efficacy--chooser p00">
                            {technologies.map( (i, idx) =>
                                <div
                                    className={`row c12 HomePage__efficacy--chooser-item p00 aCenter
                                        ${activeTechnology.name === i.name && 'active'}
                                    `}
                                    onClick={() => setActiveTechnology(i)}
                                    key={idx}
                                >
                                    <span>{i.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="column c12 HomePage__process aCenter my100">
                    <div className={`column c12 HomePage__process--info ${vw >= 768 ? 'aCenter' : undefined}`}>
                        <p className={`p--identifier primary ${vw >= 768 ? 'center' : 'left'}`}>
                            Sencillo
                        </p>
                        <h3 className={`mt00 ${vw >= 768 ? 'taCenter' : undefined}`}>
                            Cómo usas Qibo<sup>®</sup> en un, dos por tres
                        </h3>
                    </div>
                    <div className="row c12 HomePage__process--steps">
                        <div className={`row c04s ${vw < 768 ? 'px00' : undefined}`}>
                            <Slot
                                number={1}
                                title="Das de alta"
                                center={vw < 768}
                                titleHeight={vw >= 768 && vw < 992 ? 2.3 : 1}
                            >
                                ...al candidato a entrevista en Qibo<sup>®</sup> App.
                            </Slot>
                        </div>
                        <div className={`row c04s ${vw < 768 ? 'px00' : undefined}`}>
                            <Slot
                                number={2}
                                title="El candidato llama"
                                description
                                center={vw < 768}
                                titleHeight={vw >= 768 && vw < 992 ? 2.3 : 1}
                            >
                                ...a la línea de entrevistas de Qibo<sup>®</sup> y contesta el cuestionario.
                            </Slot>
                        </div>
                        <div className={`row c04s ${vw < 768 ? 'px00' : undefined}`}>
                            <Slot
                                number={3}
                                title="Obtienes el resultado"
                                center={vw < 768}
                                titleHeight={vw >= 768 && vw < 992 ? 2.3 : 1}
                            >
                                ...del análisis de la entrevista en cuestión de segundos.
                            </Slot>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row c12 HomePage__speed my100">
                    <div className="column c05s HomePage__speed--info">
                        <p className="p--identifier left primary">Rápido</p>
                        <h3 className="mt00">Resultados en tiempo récord</h3>
                        <p className="p--description">Nuestro proceso de análisis es tan rápido que lo hacemos en menos de la mitad del tiempo del récord mundial en 400 metros planos.</p>
                    </div>
                    <div className="column c07s HomePage__speed--graphs aCenter jcCenter">
                        <div className="column c12 HomePage__speed--graph aFlex-end">
                            <span className="HomePage__speed--legend-record">Récord 400 metros</span>
                            <span className="HomePage__speed--graph-bg">
                                <span className="HomePage__speed--graph-record" />
                            </span>
                        </div>
                        <div className="column c12 HomePage__speed--graph aFlex-end">
                            <span className="HomePage__speed--legend-Qibo">Qibo<sup>®</sup></span>
                            <span className="HomePage__speed--graph-bg">
                                <span className="HomePage__speed--graph-Qibo" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <FooterCta btnText="Contáctanos, somos expertos" action="/contact" />

        </div>

        <Footer />

    </>)
}

export default HomePage
