import { db } from '../firebase-config'

const useMailing = () => {


// * Biz contact mail
    const contactBizRep = (
        business_name,
        business_size,
        comments,
        contact_channel,
        country,
        email,
        first_name,
        last_name,
        phone_number,
        sales_interests
    ) => {
        const styles = {
            table: `
                border: 1px solid black;
                border-collapse: collapse;
            `,
            col01: `
                font-weight: bold;
                border: 1px solid black;
                width: 150px;
                padding: 5px;
            `,
            col02: `
                border: 1px solid black;
                width: 250px;
                padding: 5px;
            `
        }
        let contactTitle
        let interests = ''
        if (contact_channel === 'Llamada') contactTitle = 'Teléfono o celular'
        else if (contact_channel === 'Email') contactTitle = 'Email'
        else contactTitle = 'Celular'
        sales_interests.forEach( i => interests = interests.concat('<li>', `${i.text}</li>`))
        return db.collection('mail_ext')
            .add({
                to: ['alejandro@qibo.io', 'emyre@qibo.io'],
                message: {
                    subject: 'Nueva solicitud de información',
                    html: `
                        <body>
                            <p>Hola Emy</p>
                            <p>Hay un nuevo contacto a través del sitio web:</p>
                            <table style="${styles.table}">
                            <tbody>
                                <tr>
                                    <td style="${styles.col01}">
                                        Tipo de cliente
                                    </td>
                                    <td style="${styles.col02}">
                                        Business
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Día y hora
                                    </td>
                                    <td style="${styles.col02}">
                                        ${new Date()}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Empresa
                                    </td>
                                    <td style="${styles.col02}">
                                        ${business_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Tamaño de la empresa
                                    </td>
                                    <td style="${styles.col02}">
                                        ${business_size}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        País
                                    </td>
                                    <td style="${styles.col02}">
                                        ${country}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Nombre
                                    </td>
                                    <td style="${styles.col02}">
                                        ${first_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Apellido
                                    </td>
                                    <td style="${styles.col02}">
                                        ${last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Medio de contacto
                                    </td>
                                    <td style="${styles.col02}">
                                        ${contact_channel}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        ${contactTitle}
                                    </td>
                                    <td style="${styles.col02}">
                                        ${contact_channel === 'Email' ? email : phone_number}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Interesado en...
                                    </td>
                                    <td style="${styles.col02}">
                                        <ul>
                                            ${interests}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Comentarios adicionales
                                    </td>
                                    <td style="${styles.col02}">
                                        ${comments}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </body>
                    `
                }
            })
    }

// * Home contact mail
    const contactHomeRep = (
        comments,
        contact_channel,
        country,
        email,
        first_name,
        last_name,
        phone_number,
        sales_interests
    ) => {
        const styles = {
            table: `
                border: 1px solid black;
                border-collapse: collapse;
            `,
            col01: `
                font-weight: bold;
                border: 1px solid black;
                width: 150px;
                padding: 5px;
            `,
            col02: `
                border: 1px solid black;
                width: 250px;
                padding: 5px;
            `
        }
        let contactTitle
        let interests = ''
        if (contact_channel === 'Llamada') contactTitle = 'Teléfono o celular'
        else if (contact_channel === 'Email') contactTitle = 'Email'
        else contactTitle = 'Celular'
        sales_interests.forEach( i => interests = interests.concat('<li>', `${i.text}</li>`))
        return db.collection('mail_ext')
            .add({
                to: ['alejandro@qibo.io', 'emyre@qibo.io'],
                message: {
                    subject: 'Nueva solicitud de información',
                    html: `
                        <body>
                            <p>Hola Emy</p>
                            <p>Hay un nuevo contacto a través del sitio web:</p>
                            <table style="${styles.table}">
                            <tbody>
                                <tr>
                                    <td style="${styles.col01}">
                                        Tipo de cliente
                                    </td>
                                    <td style="${styles.col02}">
                                        Home
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Día y hora
                                    </td>
                                    <td style="${styles.col02}">
                                        ${new Date()}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        País
                                    </td>
                                    <td style="${styles.col02}">
                                        ${country}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Nombre
                                    </td>
                                    <td style="${styles.col02}">
                                        ${first_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Apellido
                                    </td>
                                    <td style="${styles.col02}">
                                        ${last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Medio de contacto
                                    </td>
                                    <td style="${styles.col02}">
                                        ${contact_channel}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        ${contactTitle}
                                    </td>
                                    <td style="${styles.col02}">
                                        ${contact_channel === 'Email' ? email : phone_number}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Interesado en...
                                    </td>
                                    <td style="${styles.col02}">
                                        <ul>
                                            ${interests}
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Comentarios adicionales
                                    </td>
                                    <td style="${styles.col02}">
                                        ${comments}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </body>
                    `
                }
            })
    }

// * Bug contact mail
    const contactBugOps = (
        category,
        description,
        email,
        first_name,
        last_name,
        subject
    ) => {
        const styles = {
            table: `
                border: 1px solid black;
                border-collapse: collapse;
            `,
            col01: `
                font-weight: bold;
                border: 1px solid black;
                width: 150px;
                padding: 5px;
            `,
            col02: `
                border: 1px solid black;
                padding: 5px;
            `
        }
        return db.collection('mail_ext')
            .add({
                to: ['alejandro@qibo.io'],
                message: {
                    subject: 'Nuevo reporte de ayuda',
                    html: `
                        <body>
                            <p>Hola Chompi</p>
                            <p>Hay un nuevo reporte a través del sitio web:</p>
                            <table style="${styles.table}">
                            <tbody>
                                <tr>
                                    <td style="${styles.col01}">
                                        Día y hora
                                    </td>
                                    <td style="${styles.col02}">
                                        ${new Date()}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Categoría
                                    </td>
                                    <td style="${styles.col02}">
                                        ${category}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Resumen
                                    </td>
                                    <td style="${styles.col02}">
                                        ${subject}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Descripción
                                    </td>
                                    <td style="${styles.col02}">
                                        ${description}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Nombre
                                    </td>
                                    <td style="${styles.col02}">
                                        ${first_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Apellido
                                    </td>
                                    <td style="${styles.col02}">
                                        ${last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="${styles.col01}">
                                        Email
                                    </td>
                                    <td style="${styles.col02}">
                                        ${email}
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </body>
                    `
                }
            })
    }


    return { contactBugOps, contactBizRep, contactHomeRep }

}
 export default useMailing