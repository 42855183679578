import React from 'react'
import './EfficacyCard.scss'

import DoughnutGraph from '../DoughnutGraph'


const EfficacyCard = ({ data }) => {
    return (
        <div className="row c12 EfficacyCard__container">
            <div className="row c12 pb00 jcCenter">                            
                <DoughnutGraph
                    percentage={data.percentage}
                    color={data.color}
                    size={70}
                    stroke={15}
                />
            </div>
            <div className="row c12 EfficacyCard__title p00">
                {data.name === 'Qibo' ? <p className="Qibo">Qibo<sup>®</sup></p> : <p>{data.name}</p>}
            </div>
            <div className="row c12 EfficacyCard__params p00">
                <div className={`row c12 EfficacyCard__params--row ${data.params.emotions && 'active'}`}>
                    <span className={data.color} /><span>Emociones</span>
                </div>
                <div className={`row c12 EfficacyCard__params--row ${data.params.stress && 'active'}`}>
                    <span className={data.color} /><span>Estrés</span>
                </div>
                <div className={`row c12 EfficacyCard__params--row ${data.params.cognitives && 'active'}`}>
                    <span className={data.color} /><span>Procesos cognitivos</span>
                </div>
                <div className={`row c12 EfficacyCard__params--row ${data.params.statistics && 'active'}`}>
                    <span className={data.color} /><span>Estadística</span>
                </div>
                <div className={`row c12 EfficacyCard__params--row ${data.params.perception && 'active'}`}>
                    <span className={data.color} /><span>Percepción</span>
                </div>
                <div className={`row c12 EfficacyCard__params--row ${data.params.records && 'active'}`}>
                    <span className={data.color} /><span>Registros</span>
                </div>
            </div>
            <div className={`row c12 EfficacyCard__objectiveness mt15 jcCenter
                ${!data.params.objectiveness && 'subjective'}
            `}>
                <span>Objetivo</span>
                <span>Subjetivo</span>
            </div>
        </div>
    )
}

export default EfficacyCard
