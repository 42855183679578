import React, { useEffect, useState } from 'react'
import './RankingRow.scss'

import { ReactComponent as IqonNeutral } from '../../assets/iqons/iqon_minus.svg'
import { ReactComponent as IqonTriangle } from '../../assets/iqons/iqon_play.svg'


const RankingRow = ({ activeSorting, data }) => {

    const [widths] = useState({
        col01: '70px',
        col02: '100px',
        col04: '70px'
    })
    const [sorting, setSorting] = useState({
        value: 'current_rank',
        order: 'asc'
    })
    const [variance, setVariance] = useState(0)
    const [varianceColor, setVarianceColor] = useState('null')

    useEffect( () => {
        if (data) {
            const newVariance = data.current_rank - data.previous_rank
            setVariance(newVariance)
            console.log(variance)
            if (newVariance < 0) setVarianceColor('red')
            else if (newVariance > 0) setVarianceColor('green')
            else setVarianceColor(null)
        }
    }, [data])

    const handleSorting = v => {
        let order
        if (sorting.value === v && sorting.order === 'asc') order = 'desc'
        else if (sorting.value === v && sorting.order === 'desc') order = 'asc'
        else order = 'asc'
        setSorting({value:v, order: order})
        activeSorting({value:v, order: order})
    }


    const Title = () => (<>
        <div
            className="row c01 RankingRow__title aCenter jcCenter"
            style={{width: widths.col01}}
        >
            <span
                className={sorting.order}
                onClick={() => handleSorting('current_rank')}
            >
                Lugar
                {sorting.value === 'current_rank' && <IqonTriangle />}
            </span>
        </div>
        <div
            className="row c01 RankingRow__title aCenter jcCenter"
            style={{width: widths.col02}}
        >
            Variación
        </div>
        <div
            className="row c01 RankingRow__title aCenter"
            style={{flexGrow: 1}}
        >
            <span
                className={sorting.order}
                onClick={() => handleSorting('name')}
            >
                Tema de riesgo
                {sorting.value === 'name' && <IqonTriangle />}
            </span>
        </div>
        <div
            className="row c01 RankingRow__title aCenter jcCenter"
            style={{width: widths.col04}}
        >
            <span
                className={sorting.order}
                onClick={() => handleSorting('previous_rank')}
            >
                2019
                {sorting.value === 'previous_rank' && <IqonTriangle />}
            </span>
        </div>
    </>)

    const Ranking = () => (<>
        <div
            className="row c01 RankingRow__rank aCenter jcCenter"
            style={{width: widths.col01}}
        >
            <span>{data.current_rank}</span>
        </div>
        <div
            className={`row c01 RankingRow__variance aCenter jcCenter ${varianceColor}`}
            style={{width: widths.col02}}
        >
            {variance === 0 && <IqonNeutral />}
            {variance !== 0 && <IqonTriangle />}
            {variance !== 0 && <span>{variance < 0 ? -variance : variance}</span>}
            {variance !== 0 && <IqonTriangle />}
        </div>
        <div
            className="row c01 RankingRow__name aCenter"
            style={{flexGrow: 1}}
        >
            {data.name}
        </div>
        <div
            className="row c01 RankingRow__rank-previous aCenter jcCenter"
            style={{width: widths.col04}}
        >
            <span>{data.previous_rank}</span>
        </div>
    </>)


    return (
        <div className={`row c12 RankingRow__container ${!data && 'py00'}`}>
            {!data && <Title />}
            {data && <Ranking />}
        </div>
    )
}

export default RankingRow
