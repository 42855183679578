import React, { useState } from 'react'
import { useAppWidth } from '../../Provider'
import './HousePage.scss'

import Button from '../../components/Button'
import Footer from '../../components/Footer'
import FooterCta from '../../components/FooterCta'
import Header from '../../components/Header'
import Slot from '../../components/Slot'

import imgPrice from '../../assets/images/image_house-price.svg'
import imgTests from '../../assets/images/image_house-tests.svg'
import imgTopics from '../../assets/images/image_house-topics.svg'
import { ReactComponent as IqonCalendar} from '../../assets/iqons/iqon_calendar.svg'
import { ReactComponent as IqonPerson} from '../../assets/iqons/iqon_person.svg'


const HousePage = () => {

    const vw = useAppWidth()

    const [riskList] = useState([
        // 'Abuso de confianza',
        'Maltrato infantil',
        'Abuso sexual y acoso',
        'Fuga de información',
        'Amistades peligrosas',
        'Robo',
        'Consumo de alcohol y drogas',
        'Invitación a menores a cometer malas conductas',
        'Mal uso de documentos personales de la familia',
        // 'Manejo inadecuado de medicamentos',
    ])


    return (<>

        <Header signUp />

        <div className="container fullHeight aCenter">
            <div className="column c12 HousePage__title aCenter">
                <p className="p--identifier center shade">Hogar y familia</p>
                <h1>Cuida a <span className="txt--accent">tu familia</span><br />con solo una llamada</h1>
                <p className="HousePage__title--subtitle">
                    Solo imagina que el personal de tu hogar pudiera estar
                    {vw >= 768 && <br />}cometiendo alguno de estos actos sin que tú lo sepas:
                </p>
            </div>
            <div className={`row c12 HousePage__risk-list my20 jcCenter
                ${vw < 768 ? 'p00' : undefined}
            `}>
                {riskList.map( (i, idx) =>
                    <span key={idx}>{i}</span>
                )}
            </div>
            <div className={`c12 HousePage__cta
                ${vw >= 768 ? 'row jcCenter' : 'column'}
            `}>
                <Button
                    css="btn btn--grow p20"
                    action="https://app.qibo.io/#/login"
                >
                    Crear una cuenta
                </Button>
                <Button
                    css={`btn btn--ghost p20 ${vw >= 768 ? 'ml30' : undefined}`}
                    action={{
                        pathname: '/contact',
                        state: {
                            form: 'salesHome'
                        }
                    }}
                >
                    Contáctanos, somos expertos
                </Button>
            </div>
        </div>

        <div className="container my100">
            <div className="column c12 HousePage__moments--title aCenter">
                <p className="p--identifier center primary">Momentos</p>
                <h3 className="mt00 taCenter">Para qué usar Qibo<sup>®</sup></h3>
            </div>
            <div className={`row c12 HousePage__moments--items ${vw < 768 ? 'p00' : undefined}`}>
                <div className="row c06s">
                    <Slot
                        Iqon={IqonPerson}
                        title="Contratación"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Prevenir es muy sencillo, siempre que lo hagas desde antes que la persona empiece a trabajar en tu hogar.
                    </Slot>
                </div>
                <div className="row c06s">
                    <Slot
                        Iqon={IqonCalendar}
                        title="Prevención periódica"
                        titleHeight={vw >= 768 ? 2.3 : 1}
                    >                        
                        Las personas cambiamos constantemente y nuevas circunstancias de vida pueden hacer que alguien haga algo que antes no hacía. Por eso evaluar al personal del horgar periódicamente es indispensable.
                    </Slot>
                </div>
            </div>
        </div>

        <div className="container HousePage__features">
            <div className="row c12 BizPage__feature">
                <div className="column c05s HousePage__features--info">
                    <p className="p--identifier left primary">Funciones</p>
                    <h3 className="mt00">Conoce qué personas pueden dañar a tu familia</h3>
                    <p className="p--description">En menos tiempo de lo que te tardaría en llegar una pizza, puedes conocer con un 95 % de certeza qué personas le pueden causar o le están causando un daño a tu familia. No dejemos a nuestro tesoro más valioso en más de quien sea.</p>
                </div>
                <div className="row c07s HousePage__features--risk-level">
                    <img src={imgTopics} alt="Temas de riesgo de la empresa" />
                </div>
            </div>
            <div className="row c12 BizPage__feature">
                <div className="row c07s HousePage__features--risk-topics">
                    <img src={imgTests} alt="Temas de riesgo de la empresa" />
                </div>
                <div className="column c05s HousePage__features--info">
                    <h3 className="mt00">Elige la problemática de más cuidado para ti y tu familia</h3>
                    <p className="p--description">Tenemos un catálogo de cuestionarios listos para utilizarse enfocados en los distintos riesgos que podrían dañar a tu familia. Solo necesitas elegir aquel que representa el mayor nivel de riesgo para ti y, ¡listo!</p>
                </div>
            </div>
            <div className="row c12 BizPage__feature">
                <div className="column c05s HousePage__features--info">
                    <h3 className="mt00">Todo esto a un precio que no creerás</h3>
                    <p className="p--description">No vas a creer lo económico que es proteger a tus hijos de maltrato por parte del personal del hogar. O conocer si la información personal de tu familia se la están filtrando a delincuentes. O incluso saber qué personal está sacando bienes de tu casa sin que tú lo sepas.</p>
                </div>
                <div className="row c07s HousePage__features--risk-people">
                    <img src={imgPrice} alt="Temas de riesgo de la empresa" />
                </div>
            </div>
        </div>

        <FooterCta btnText="Crear cuenta" action="https://app.qibo.io/#/login" />

        <Footer />

    </>)
}

export default HousePage
