import React, { useState } from 'react'
import './RankingPage.scss'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import RankingCard from '../../components/RankingRow'


const RankingPage = () => {

    const [ranking, setRanking] = useState([
        {
            name: 'Corrupción y soborno',
            columns: 6,
            current_rank: 1,
            previous_rank: 5,
        },
        {
            name: 'Colusión',
            columns: 3,
            current_rank: 2,
            previous_rank: 2,
        },
        {
            name: 'Robo',
            columns: 3,
            current_rank: 3,
            previous_rank: 3,
        },
        {
            name: 'Confidencialidad y secreto',
            columns: 12,
            current_rank: 4,
            previous_rank: 4,
        },
        {
            name: 'Estabilidad personal',
            columns: 6,
            current_rank: 5,
            previous_rank: 28,
        },
        {
            name: 'Vinculación criminal',
            columns: 6,
            current_rank: 6,
            previous_rank: 1,
        },
        {
            name: 'Estafa y fraude',
            columns: 5,
            current_rank: 7,
            previous_rank: 10,
        },
        {
            name: 'Comisión de delitos',
            columns: 7,
            current_rank: 8,
            previous_rank: 11,
        },
        {
            name: 'Cumplimiento institucional',
            columns: 7,
            current_rank: 9,
            previous_rank: 9,
        },
        {
            name: 'Alcohol y drogas',
            columns: 5,
            current_rank: 10,
            previous_rank: 7,
        },
        {
            name: 'Incitación y motivación a cometer delitos',
            columns: 12,
            current_rank: 11,
            previous_rank: 6,
        },
        {
            name: 'Agresión y amenazas',
            columns: 6,
            current_rank: 12,
            previous_rank: 8,
        },
        {
            name: 'Tráfico de mercancías',
            columns: 6,
            current_rank: 13,
            previous_rank: 19,
        },
        {
            name: 'Alteración de cargas',
            columns: 6,
            current_rank: 14,
            previous_rank: 13,
        },
        {
            name: 'Antecedentes laborales ocultos',
            columns: 6,
            current_rank: 15,
            previous_rank: 18,
        },
    ])

    const handleSorting = v => {
        let newValues = [...ranking]
        newValues.sort( (a, b) => {
            let aSort = isNaN(a[v.value]) ? a[v.value].toLocaleLowerCase() : a[v.value]
            let bSort = isNaN(b[v.value]) ? b[v.value].toLocaleLowerCase() : b[v.value]

            if (v.order === 'asc') {
                if (aSort > bSort) return 1
                else if (aSort < bSort) return -1
                else return 0
            }
            else {
                if (aSort < bSort) return 1
                else if (aSort > bSort) return -1
                else return 0
            }
        })
        setRanking(newValues)
    }


    return (<>
        <Header />

        <div className="container jcCenter">
            <div className="column c12 RankingPage__title mt50 mb30">
                <h1 className="taCenter">Top 15 de riesgos 2020</h1>
            </div>
            <div className="column c04s RankingPage__description">
                <p className="ls1-8">Estos son los 15 riesgos más altos que hemos encontrado en nuestros procesos de detección de riesgos que realizamos en las empresas en México.</p>
                <p className="ls1-8">Este ranking muestra un claro ejemplo de los cambios en las conductas de las personas antes y durante la pandemia de Covid-19.</p>
            </div>
            <div className="row c08s RankingPage__ranking-list mb200">
                <RankingCard activeSorting={v => handleSorting(v)} />
                {ranking.map( (i, idx) =>
                    <RankingCard key={idx} data={i}/>
                )}
            </div>
        </div>

        <Footer />

    </>)
}

export default RankingPage
