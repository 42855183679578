import React, { useState } from 'react'
import { useAppWidth } from '../../Provider'
import './HistoryPage.scss'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import logoEthics from '../../assets/images/logo_Ethics.svg'
import logoEthicsOld from '../../assets/images/logo_Ethics-old.svg'
import logoHonestidad from '../../assets/images/logo_Honestidad-Laboral.svg'
import logoQibo from '../../assets/images/logo_Qibo-io.svg'


const HistoryPage = () => {

    const vw = useAppWidth()

    const [classNameDescription] = useState(vw >= 768
        ? 'column c11 HistoryPage__event-line--description shadow--grow'
        : 'column c12 HistoryPage__event-line--description shadow--grow')


    return (<>

        <Header />

        <div className="container">

            <div className={`column c12 HistoryPage__title ${vw >= 768 ? 'aCenter' : undefined}`}>
                <h1 className={vw >= 768 ? 'taCenter mb20' : 'mb00 ml20'}>
                    Un vistazo a
                    <br/>nuestros {vw < 768 && <br />}<span className="txt--accent">10 años</span>
                </h1>
                {/* <p className="m00">¿Por qué Ethics ahora se llama Qibo<span className="sup">®</span>? Veamos un poco hacia atrás</p> */}
            </div>

            <div className={`column c12 HistoryPage__timeline p00
                ${vw >= 768 ? 'mt20' : undefined}
            `}>
                <div className="row c12 HistoryPage__today">
                    <span><span>HOY</span></span>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left" />
                    <div className="row c06 HistoryPage__event-line--right" />
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left">
                        <div className={classNameDescription}>
                            <h5>Lanzamiento de Qibo 7.0</h5>
                            <p>Después de meses de desarrollo y para celebrar nuestro décimo aniversario, lanzamos Qibo 7.0 con nuevas funcionalidades, rendimiento mejorado y un diseño más atractivo.</p>
                        </div>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2021</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className='row c06 HistoryPage__event-line--left interchange'>
                        <span className="HistoryPage__event-line--date-left interchange">2020</span>
                    </div>
                    <div className='row c06 HistoryPage__event-line--right interchange'>
                        <div className={classNameDescription}>
                            <h5>Tus inquilinos podrían también ser un riesgo</h5>
                            <p>Comprometidos con ayudar a proteger tu patrimonio, creamos la evaluación perfecta para conocer si tus futuros inquilinos tienen alguna intención engañosa.</p>
                        </div>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left pb00">
                        <div className={`${classNameDescription} top`}>
                            <h5>Antes nos llamábamos Ethics</h5>
                            <p>"Si la ética no se puede medir, ¿por qué se llaman Ethics?"</p>
                            <p>Qué buena pregunta; nos hizo pensar. Por eso decidimos darle un cambio completo a nuestro nombre e imagen.</p>
                            <p>A quien conocías como Ethics, <strong className="txt--primary">ahora lo reconoces como Qibo<sup>®</sup>.</strong></p>
                            <div className="row c12 HistoryPage__event-line--image">
                                <div className="column c06 logo-ethics py00">
                                    <img src={logoEthics} alt="Logo Ethics" />
                                </div>
                                <div className="column c06 logo-qibo py00">
                                    <img src={logoQibo} alt="Logo Qibo" />
                                </div>
                                <div className="column c06 logo-ethics p00 mt10">
                                    <span>Misma empresa</span>
                                </div>
                                <div className="column c06 logo-qibo p00 mt10">
                                    <span>Nuevo nombre</span>
                                </div>
                            </div>
                            <p className="warning"><strong>Importante:</strong> no te dejes engañar, hoy NINGUNA marca ni filial de Qibo<sup>®</sup> continúa utilizando el nombre comercial de Ethics o similares.</p>
                        </div>
                        <div className={`${classNameDescription} bottom`}>
                            <h5>En cuestión de segundos</h5>
                            <p>Nuestro equipo siempre está enfocado en dar el mejor servicio, tanto en certeza como en rapidez. Es así como después de muchas pruebas, logramos reducir el tiempo de análisis de todas la entrevistas de 24 horas hábiles a cuestión de segundos.</p>
                        </div>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2019</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left interchange">
                        <span className="HistoryPage__event-line--date-left interchange">2018</span>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right interchange">
                        <div className={classNameDescription}>
                            <h5>Protegemos a tu empresa... y tu familia</h5>
                            <p>La seguridad de la familia es tan o más importante como la de tu empresa. Es así que decimos crear Ethics Home (hoy Qibo<sup>®</sup> Personal), la herramienta perfecta para evaluar al personal que labora en tu hogar.</p>
                        </div>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left">
                        {/* <div className={classNameDescription}>
                            <h5>Lanzamiento de Qibo 7.0</h5>
                            <p>Después de meses de desarrollo y para celebrar nuestro décimo aniversario, lanzamos Qibo 7.0 con nuevas funcionales, rendimiento mejorado y un diseño más atractivo.</p>
                        </div> */}
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2017</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left interchange">
                        <span className="HistoryPage__event-line--date-left interchange">2016</span>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right interchange">
                        {/* <div className={classNameDescription}>
                            <h5>Lanzamiento de Qibo 7.0</h5>
                            <p>Después de meses de desarrollo y para celebrar nuestro décimo aniversario, lanzamos Qibo 7.0 con nuevas funcionales, rendimiento mejorado y un diseño más atractivo.</p>
                        </div> */}
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left">
                        <div className={classNameDescription}>
                            <h5>Mientras más rápido, mejor</h5>
                            <p>Aunque nos tomó unos años poder dar este gran paso, por fin lo logramos: lanzamos la versión 4.0 de nuestra aplicación web.</p>
                            <p>Este lanzamiento trae un nuevo logro en favor de nuestros clientes, pues pasamos de resultados en 5 días a resultados en 24 horas.</p>
                        </div>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2015</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left interchange">
                        <span className="HistoryPage__event-line--date-left interchange">2014</span>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right interchange">
                        <div className={classNameDescription}>
                            <h5>Cambiamos de nombre</h5>
                            <p>Siempre hemos buscado la mejor forma de ayudar a nuestros clientes, sobre todo en los temas de riesgo de cumplimento ético.</p>
                            <p>Este es nuestro primer cambio de nombre e imagen. Pasamos de ser Honestidad Laboral a convertirnos en Ethics.</p>
                            <div className="column c12 HistoryPage__event-line--image">
                                <img src={logoEthicsOld} alt="Logo Ethics" className="logo-ethics-old"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left">
                    <div className={`${classNameDescription} top`}>
                            <h5>Nace la evaluación periódica</h5>
                            <p>En un inicio únicamente trabajábamos con las empresas en sus procesos de reclutamiento. Pero después de un profundo análisis vimos que evaluar al personal activo de la empresa es tan importante como evaluar al de nuevo ingreso.</p>
                        </div>
                        <div className={`${classNameDescription} bottom`}>
                            <h5>Nuestro IVR llegó para quedarse</h5>
                            <p>Aquí llega uno de los grandes cambios de nuestra historia. A fin de poder falicitar la aplicación de todas nuestras evaluaciones para una gran número de empresas, creamos nuestro novedoso sistema de entrevsistas por IVR.</p>
                            <p>A partir de este momento, tú decides cuándo y a cuántos candidatos evaluar, sin límites de horarios o volumen.</p>
                        </div>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2013</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left interchange">
                        <span className="HistoryPage__event-line--date-left interchange">2012</span>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right interchange">
                        <div className={classNameDescription}>
                            <h5>Versión 2.0</h5>
                            <p>Todos nuestros resultados siempre han sido los más certeros del mercado, pero no siempre han sido los más rápidos.</p>
                            <p>Para darte un mejor servicio, mejoramos nuestro algoritmo para la entrega de resultados, pasando de 10 días a tan solo 5; pero todavía nos queda camino por recorrer.</p>
                        </div>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left">
                        <div className={classNameDescription}>
                            <h5>Y aquí empezamos</h5>
                            <p>Este es el año en el que inciamos operaciones. En ese momento nos llamábamos Honestidad Laboral. Lo sabemos, no era el mejor nombre, pero sí la mejor tecnología.</p>
                            <div className="column c12 HistoryPage__event-line--image">
                                <img src={logoHonestidad} alt="Logo Honestidad Laboral" className="logo-honestidad"/>
                            </div>
                        </div>
                    </div>
                    <div className="row c06 HistoryPage__event-line--right">
                        <span className="HistoryPage__event-line--date-right">2011</span>
                    </div>
                </div>
                <div className="row c12 HistoryPage__event-line p00">
                    <div className="row c06 HistoryPage__event-line--left py50" />
                    <div className="row c06 HistoryPage__event-line--right py50" />
                </div>
            </div>

        </div>

        <Footer />

    </>)
}

export default HistoryPage
