import React from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import './App.scss'

import BizPage from './pages/BizPage'
import ContactPage from './pages/ContactPage'
import HelpCenterPage from './pages/HelpCenterPage'
import HistoryPage from './pages/HistoryPage'
import HousePage from './pages/HousePage'
import HomePage from './pages/HomePage'
import PressPage from './pages/PressPage'
import PrivacyPage from './pages/PrivacyPage'
import RankingPage from './pages/RankingPage'
import TermsPage from './pages/TermsPage'

import ScrollTop from './components/ScrollTop'


const App = () => {
  return (
    <Router forceRefresh={true} >
      {/* <ScrollTop /> */}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/biz" component={BizPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/help-center" component={HelpCenterPage} />
        <Route exact path="/history" component={HistoryPage} />
        <Route exact path="/home" component={HousePage} />
        <Route exact path="/press" component={PressPage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/ranking" component={RankingPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default App
