import React, { useState } from 'react'
import './PrivacyPage.scss'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Multibutton from '../../components/Multibutton'
import Button from '../../components/Button'


const PrivacyPage = () => {

    const [type, setType] = useState('client')

    const ClientsPrivacy = () => (<>
        <div className="column c12 PrivacyPage__section--text">
            <p><strong>ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V. (EN ADELANTE IDENTIFICADO COMO “QIBO”) COMO EMPRESA RESPONSABLE Y RESPETUOSA DE LOS DATOS PERSONALES, PONE A SU DISPOSICIÓN EL PRESENTE AVISO DE PRIVACIDAD CON EL OBJETO DE GARANTIZAR EL DERECHO DE LOS TITULARES A LA AUTODETERMINACIÓN INFORMATIVA.</strong></p>
            <p><strong>EL PRESENTE AVISO DE PRIVACIDAD SE PONE A DISPOSICIÓN DE LOS TITULARES EN EL SITIO DE INTERNET QUE OPERA BAJO EL NOMBRE DE DOMINIO https://qibo.io/ (EL “SITIO WEB”).</strong></p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Responsable: identidad y domicilio</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>El responsable del tratamiento de los Datos Personales es la persona moral denominada <strong>ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V. (“QIBO”),</strong> con domicilio en Av. Jesús de Monte 32, interior 101, colonia Jesús del Monte, Huixquilucan, C.P. 52764, Estado de México, México, entidad que se compromete a respetar lo establecido en el presente Aviso de Privacidad, así como las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”), su Reglamento y de los Lineamientos del Aviso de Privacidad.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Datos personales sometidos a tratamiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En virtud del presente Aviso de Privacidad, <strong>QIBO</strong> hace del conocimiento de los Titulares que someterá a tratamiento los Datos Personales que a continuación se señalan:</p>
            <ol className="latin">
                <li>Datos Personales de Clientes.</li>
                <ol className="roman">
                    <li>Datos de identificación.</li>
                    <li>Datos de contacto</li>
                </ol>
                <li className="mt10">Datos Personales Sensibles.</li>
                <ol className="roman">
                    <li><strong>QIBO</strong> no tratará datos personales sensibles de sus Clientes.</li>
                </ol>
            </ol>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Finalidades del tratamiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>El tratamiento de los Datos Personales que lleva a cabo <strong>QIBO</strong> tiene como objetivo el cumplimiento de las siguientes finalidades:</p>
            <ol className="latin">
                <li>FINALIDADES PRIMARIAS.</li>
                <ol className="roman">
                    <li>Autentificar la identidad del Titular.</li>
                    <li>Creación de la Cuenta de Usuario del Titular en el Sitio Web de <strong>QIBO.</strong></li>
                    <li>Brindar acceso al Titular al Sitio Web de <strong>QIBO.</strong></li>
                    <li>Contactar al Titular a fin de gestionar y dar seguimiento a sus solicitudes de servicios.</li>
                    <li>Contactar al Titular para la formulación y seguimiento de quejas, sugerencias y comentarios sobre los servicios contratados.</li>
                    <li>Proporcionar los servicios al Titular, de conformidad con los Términos y Condiciones del Sitio Web.</li>
                </ol>
                <li className="mt10">FINALIDADES SECUNDARIAS.</li>
                <ol className="roman">
                    <li>Envío de información promocional y publicitaria de <strong>QIBO,</strong> así como información sobre nuevos servicios o productos, invitaciones a eventos, trivias, promociones y boletines informativos.</li>
                </ol>
            </ol>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Mecanismos para que el titular pueda manifestar su negativa para el tratamiento de sus datos personales para las finalidades secundarias</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En el supuesto que el Titular no desee que sus Datos Personales sean utilizados para todas o alguna de las Finalidades Secundarias contenidas en el apartado anterior, deberá enviar un correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Negativa de Tratamiento para Finalidades Secundarias”. En el cuerpo del correo, el Titular deberá especificar para qué finalidades desea manifestar su negativa, así como sus datos de identificación, para que se le dé cumplimiento a su solicitud.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Transferencias de datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>Se hace del conocimiento de los Titulares que <strong>QIBO</strong> llevará a cabo las siguientes transferencias:</p>
            <table>
                <thead>
                    <tr>
                        <th>Tercero receptor</th>
                        <th>Finalidad</th>
                        <th>Consentimiento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Autoridades administrativas y/o judiciales</td>
                        <td>Cumplimiento de disposiciones legales</td>
                        <td>No necesario</td>
                    </tr>
                    <tr>
                        <td>Autoridades administrativas y/o judiciales</td>
                        <td>Cumplimiento de disposiciones legales</td>
                        <td>No necesario</td>
                    </tr>
                </tbody>
            </table>
            <p><strong>QIBO</strong> efectuará las Transferencias anteriores bajo el amparo del artículo 37 de la Ley. En ese sentido, no es necesario el consentimiento de los Titulares.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios y procedimiento para el ejercicio de derechos ARCO</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p><strong>QIBO</strong> reconoce a los Titulares la posibilidad de ejercer Derechos ARCO sobre sus Datos Personales, que consisten en lo siguiente:</p>
            <ol className="latin">
                <li><strong>Derecho de Acceso:</strong> Conocer con cuáles de sus Datos Personales cuenta <strong>QIBO</strong> y cuáles son las finalidades de su tratamiento.</li>
                <li><strong>Derecho de Rectificación:</strong> Solicitar la corrección de sus Datos Personales en caso de que carezcan de actualización, sean inexactos, erróneos o estén incompletos.</li>
                <li><strong>Derecho de Cancelación:</strong> Solicitud de eliminación de todo registro o copia de sus Datos Personales, ya sea física o electrónica.</li>
                <li><strong>Derecho de Oposición:</strong> Solicitar el cese en el uso de sus Datos Personales.</li>
            </ol>
            <p>El ejercicio de los derechos descritos en el presente numeral se efectúa mediante solicitud por escrito enviada vía correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Ejercicio de Derechos ARCO”. La dirección electrónica mencionada corresponde al departamento que dará trámite a las solicitudes en cuestión y que podrá ser contactado por los Titulares en cualquier tiempo.</p>
            <p>El Titular deberá proporcionar la información siguiente:</p>
            <ol className="latin">
                <li>Nombre completo y domicilio.</li>
                <li>Descripción clara y precisa de los Datos Personales respecto de los cuales pretende ejercer alguno de los Derechos ARCO, además de cualquier documento que permita ubicarlos.</li>
                <li>Mención del Derecho ARCO que desea ejercer y la motivación para hacerlo.</li>
                <li>Los argumentos que sustenten su posición.</li>
                <li>En caso de que se solicite el ejercicio del Derecho de Rectificación, se deberán indicar las modificaciones por realizar y se deberá aportar la documentación que acredite la razón de los cambios solicitados.</li>
            </ol>
            <p>Asimismo, la solicitud deberá acompañarse de los siguientes documentos:</p>
            <ol className="latin">
                <li>Identificación oficial del Titular o de su representante.</li>
                <li>Documento que acredite que la información personal se encuentra en posesión de <strong>QIBO.</strong></li>
                <li>En el supuesto que el Titular no presente personalmente la solicitud, quien lo haga deberá acreditar su representación. Podrá hacerlo mediante escritura pública o carta poder firmada ante dos testigos, junto con identificaciones oficiales de Titular y representante.</li>
            </ol>
            <p>Una vez recibida la solicitud del Titular, <strong>QIBO</strong> le hará llegar vía correo electrónico un acuse de recibo. Posteriormente, responderá a la petición del Titular por correo electrónico en un plazo que no podrá exceder de 20 (veinte) días hábiles contados a partir de que se recibió. Este plazo únicamente será aplicable siempre y cuando <strong>QIBO</strong> cuente con la información suficiente para poder atender la solicitud, en términos de lo establecido en el siguiente párrafo.</p>
            <p>El Titular debe tomar en consideración que, a fin de contar con elementos suficientes para atender su petición, <strong>QIBO</strong> podrá solicitarle información adicional dentro de los 5 (cinco) días hábiles siguientes a la recepción de la solicitud. A partir del requerimiento de <strong>QIBO,</strong> El Titular contará con un plazo de 10 (diez) días hábiles para desahogar este requerimiento. En este supuesto, el plazo de 20 (veinte) días hábiles mencionado en el párrafo anterior empezará a correr al día siguiente de aquél en el que el Titular hubiere atendido el requerimiento de <strong>QIBO.</strong></p>
            <p>Si la solicitud es declarada procedente y el Titular ha acreditado su personalidad y la titularidad del Derecho ARCO que pretendió ejercer, éste se hará efectivo en un plazo máximo de 15 (quince) días hábiles contados a partir de la fecha en que se le haya dado respuesta a la solicitud.</p>
            <p>Tratándose de solicitudes de Acceso a Datos Personales, procederá su entrega previa acreditación de la identidad del solicitante o representante, según corresponda. Dicha entrega se hará en copias simples en el domicilio mencionado en el presente, o bien, en documentos electrónicos a la dirección de correo desde la que se hubiere remitido la solicitud.</p>
            <p>En caso de que el objeto de la solicitud sea la Oposición, el tratamiento de los Datos Personales cesará el día hábil inmediato siguiente a la fecha en la que se le haya comunicado la respuesta afirmativa al Titular.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios y procedimiento para la revocación del procedimiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En cualquier momento y por cualquier causa, el Titular podrá revocar la autorización que le ha dado a <strong>QIBO</strong> para el tratamiento de sus Datos Personales. <strong>QIBO</strong> manifiesta que, para el cumplimiento de ciertos fines, la revocación del consentimiento podría significar la imposibilidad de continuar con la relación entre ambas partes.</p>
            <p>En su caso, la revocación del consentimiento se llevará a cabo de acuerdo con el siguiente procedimiento.</p>
            <ol className="latin">
                <li>Envío de un correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Revocación del Consentimiento”.</li>
                <li>En el cuerpo del mensaje se deberá señalar:</li>
                <ol className="roman">
                    <li>El nombre completo del Titular.</li>
                    <li>El motivo de su solicitud.</li>
                    <li>Los argumentos que sustenten su petición.</li>
                    <li>Fecha a partir de la cual desea que se haga efectiva la revocación.</li>
                    <li>Documento oficial que acredite su identidad, que se deberá adjuntar a la solicitud.</li>
                </ol>
            </ol>
            <p>En el supuesto que el Titular no presente por su propio derecho la solicitud, quien lo haga deberá acreditar su representación. Podrá hacerlo mediante escritura pública o carta poder firmada ante dos testigos, junto con identificaciones oficiales de Titular y representante.</p>
            <p>QIBO responderá a su solicitud mediante correo electrónico en un plazo de 20 (veinte) días hábiles contados a partir de la fecha en la que se recibió. Este plazo únicamente será aplicable siempre y cuando <strong>QIBO</strong> cuente con la información suficiente para poder atender la solicitud, en términos de lo establecido en el siguiente párrafo.</p>
            <p>El Titular debe tomar en consideración que, a fin de contar con elementos suficientes para atender su petición, <strong>QIBO</strong> podrá solicitarle información adicional dentro de los 5 (cinco) días hábiles siguientes a la recepción de la solicitud. A partir del requerimiento de <strong>QIBO,</strong> el Titular contará con un plazo de 10 (diez) días hábiles para desahogar este requerimiento. En este supuesto, el plazo de 20 (veinte) días hábiles mencionado en el párrafo anterior empezará a correr al día siguiente de aquél en el que el Titular hubiere atendido el requerimiento de <strong>QIBO.</strong></p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Responsable de tramitar solicitudes de derechos ARCO, limitación de uso y divulgación, así como revocaciones</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En caso de que el Titular desee revocar su consentimiento, limitar el uso y divulgación de su información, así como Acceder, Rectificar, Cancelar u Oponerse al Tratamiento de sus Datos Personales, lo deberá hacer a través del Departamento designado por <strong>QIBO,</strong> cuyos datos de contacto se enuncian a continuación:</p>
            <p>Responsable: <strong>DEPARTAMENTO DE PROTECCIÓN DE DATOS PERSONALES DE <strong>QIBO.</strong></strong></p>
            <p>Correo electrónico: servicioaclientes@qibo.io</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios para limitar el uso o divulgación de los datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p> <strong>QIBO,</strong> en aras de garantizar la protección de los Datos Personales de los Titulares, se compromete a la celebración de actos jurídicos, la adopción de políticas de privacidad, de protección de Datos Personales y de seguridad de la información exigibles al interior de su organización, así como a implementar medidas de seguridad de carácter tecnológico, físico y administrativo.</p>
            <p><strong>QIBO</strong> almacena la información personal en bases de datos con acceso limitado que se encuentran en instalaciones controladas con mecanismos de seguridad. <strong>QIBO</strong> se compromete a que la información proporcionada por el Titular sea considerada y tratada con carácter confidencial.</p>
            <p><strong>QIBO</strong> brinda mecanismos alternos al ejercicio de Derechos ARCO para que los Titulares limiten el uso y divulgación de sus Datos Personales. En este sentido, hace del conocimiento del Titular que, para evitar recibir publicidad, puede inscribirse en el Registro Público para Evitar Publicidad (REPEP) de la Procuraduría Federal del Consumidor (PROFECO), en el vínculo https://repep.profeco.gob.mx/</p>
            <p>En caso de que el Titular desee conocer más sobre lo abordado en este apartado, podrá enviar un correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Limitación de Uso o Divulgación”, expresando sus dudas, comentarios y sugerencias con respecto al objeto del presente.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Plazo de almacenamiento de datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En cumplimiento al principio de calidad, todos los Datos Personales del Titular serán resguardados por el tiempo necesario para cumplir con las finalidades para las que fueron recabados. Posteriormente, se procederá a un periodo de bloqueo previo a su cancelación.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Uso de mecanismos en medios remotos o locales de comunicaciín electrónica, óptica u otra tecnología, de recopilación y rastreo, automático y simultáneo, de datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>Las cookies son archivos de datos que se almacenan en el disco duro del equipo de cómputo o dispositivo del Titular al navegar en un sitio web, los cuales permiten intercambiar información de estado entre dicho sitio y el navegador del Titular.</p>
            <p>El Sitio Web de <strong>QIBO</strong> hace uso de cookies en conexión con ciertas características o funciones. Las cookies sirven para facilitar el uso del Sitio Web, al guardar contraseñas y preferencias mientras el titular navega.  El Sitio Web no usa cookies para obtener datos de identificación personal del dispositivo del Titular que no se hayan enviado originalmente.</p>
            <p>Aunque la mayoría de los navegadores aceptan cookies y web beacons automáticamente, el Titular puede configurar su navegador para que no los acepte. Para desactivar las cookies, debe seguir las siguientes instrucciones:</p>
            <ol className="roman">
                <li>Internet Explorer: https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d</li>
                <li>Firefox: https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?e=es&redirectlocale=es&as=s&s=deshabilitar+cookies&r=0&redirectslug=Habilitar+y+deshabilitar+cookies</li>
                <li>Safari: https://www.apple.com/legal/privacy/es/cookies/</li>
                <li>Chrome: https://support.google.com/chrome/answer/95647?hl=es</li>
            </ol>
            <p>Mediante el uso de cookies, el Sitio Web de <strong>QIBO</strong> recabará los siguientes datos:</p>
            <ol className="roman">
                <li>Dirección IP.</li>
                <li>Usuario y contraseña creados por el Titular para acceder al Sitio Web.</li>
            </ol>
            <p>Dichos Datos Personales serán tratados únicamente con la finalidad de autenticación y prevención frente al robo de identidad o vulneración de la seguridad, así como para autenticar o identificar usuarios o terceros que utilicen el Sitio Web. No se realizará transferencia alguna de dichos Datos Personales a terceros ajenos a <strong>QIBO.</strong></p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Modificaciones al aviso de privacidad</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>Se hace del conocimiento del Titular que el presente Aviso de Privacidad podrá ser modificado en el tiempo y forma que <strong>QIBO</strong> lo determine, atendiendo en todo caso a la legislación en la materia.</p>
            <p><strong>QIBO</strong> informa a los Titulares que los cambios al presente serán notificados mediante la publicación de un mensaje informativo y de su versión actualizada en el Sitio Web y/o mediante del envío de un correo electrónico a la dirección que el Titular hubiere proporcionado.</p>
        </div>
    </>)

    const IntervieweePrivacy = () => (<>
        <div className="column c12 PrivacyPage__section--text">
            <p><strong>ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V. (EN ADELANTE IDENTIFICADO COMO “QIBO”) COMO EMPRESA RESPONSABLE Y RESPETUOSA DE LOS DATOS PERSONALES, PONE A SU DISPOSICIÓN EL PRESENTE AVISO DE PRIVACIDAD CON EL OBJETO DE GARANTIZAR EL DERECHO DE LOS TITULARES A LA AUTODETERMINACIÓN INFORMATIVA.</strong></p>
            <p><strong>EL PRESENTE AVISO DE PRIVACIDAD SE PONE A DISPOSICIÓN DE LOS TITULARES EN EL SITIO DE INTERNET QUE OPERA BAJO EL NOMBRE DE DOMINIO https://qibo.io/ (EL “SITIO WEB”).</strong></p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Responsable: identidad y domicilio</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>El responsable del tratamiento de los Datos Personales es la persona moral denominada <strong>ESPECIALISTAS EN PREVENCIÓN Y ANÁLISIS, S.A. DE C.V. (“QIBO”),</strong> con domicilio en Av. Jesús de Monte 32, interior 101, colonia Jesús del Monte, Huixquilucan, C.P. 52764, Estado de México, México, entidad que se compromete a respetar lo establecido en el presente Aviso de Privacidad, así como las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”), su Reglamento y de los Lineamientos del Aviso de Privacidad.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Datos personales sometidos a tratamiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En virtud del presente Aviso de Privacidad, <strong>QIBO</strong> hace del conocimiento de los Titulares que someterá a tratamiento los Datos Personales que a continuación se señalan:</p>
            <ol className="latin">
                <li>Datos Personales de Entrevistados.</li>
                <ol className="roman">
                    <li>Datos de identificación.</li>
                    <li>Datos de contacto.</li>
                </ol>
                <li className="mt10">Datos Personales Sensibles.</li>
                <ol className="roman">
                    <li>Datos sobre afiliación sindical.</li>
                    <li>Datos de salud.</li>
                    <li>Datos sobre antecedentes penales.</li>
                </ol>
            </ol>
            <p>En términos de lo dispuesto en los artículos 8 y 9 de la Ley, el Titular otorga su consentimiento expreso para que <strong>QIBO</strong> someta a Tratamiento sus Datos Personales, incluyendo aquellos de carácter sensible.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Finalidades del tratamiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>El tratamiento de los Datos Personales que lleva a cabo <strong>QIBO</strong> tiene como objetivo el cumplimiento de las siguientes finalidades:</p>
            <ol className="latin">
                <li>FINALIDADES PRIMARIAS.</li>
                <ol className="roman">
                    <li>Autentificar la identidad del Titular.</li>
                    <li>Realizar el cuestionario contratado por los Clientes de <strong>QIBO.</strong></li>
                    <li>Realizar el análisis de voz de las respuestas proporcionadas por los Titulares.</li>
                    <li>Emitir los resultados de los análisis de voz efectuados.</li>
                </ol>
                <li className="mt10">FINALIDADES PARA DATOS PERSONALES SENSIBLES.</li>
                <ol className="roman">
                    <li>Llevar a cabo el cuestionario contratado por los Clientes de <strong>QIBO.</strong></li>
                    <li>Detectar el riesgo que representa el Titular para el Cliente de <strong>QIBO</strong> con base en las preguntas que se le hayan efectuado.</li>
                </ol>
                <li className="mt10">FINALIDADES SECUNDARIAS.</li>
                <ol className="roman">
                    <li><strong>QIBO</strong> no trata los Datos Personales para finalidades secundarias.</li>
                </ol>
            </ol>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Mecanismos para que el titular pueda manifestar su negativa para el tratamiento de sus datos personales para las finalidades secundarias</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p><strong>QIBO</strong> no trata los Datos Personales para finalidades secundarias.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Transferencias de datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>Se hace del conocimiento de los Titulares que <strong>QIBO</strong> llevará a cabo las siguientes transferencias:</p>
            <table>
                <thead>
                    <tr>
                        <th>Tercero receptor</th>
                        <th>Finalidad</th>
                        <th>Consentimiento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Cliente de <strong>QIBO</strong> que solicitó la entrevista del Titular.</td>
                        <td>Cumplir con un contrato celebrado por <strong>QIBO</strong> y su cliente, en interés del titular.</td>
                        <td>No necesario</td>
                    </tr>
                    <tr>
                        <td>Autoridades administrativas y/o judiciales.</td>
                        <td>Cumplimiento de disposiciones legales</td>
                        <td>No necesario</td>
                    </tr>
                </tbody>
            </table>
            <p><strong>QIBO</strong> efectuará las Transferencias anteriores bajo el amparo del artículo 37 de la Ley. En ese sentido, no es necesario el consentimiento de los Titulares.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios y procedimiento para el ejercicio de derechos ARCO</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p><strong>QIBO</strong> reconoce a los Titulares la posibilidad de ejercer Derechos ARCO sobre sus Datos Personales, que consisten en lo siguiente:</p>
            <ol className="latin">
                <li><strong>Derecho de Acceso:</strong> Conocer con cuáles de sus Datos Personales cuenta <strong>QIBO</strong> y cuáles son las finalidades de su tratamiento.</li>
                <li><strong>Derecho de Rectificación:</strong> SSolicitar la corrección de sus Datos Personales en caso de que carezcan de actualización, sean inexactos, erróneos o estén incompletos.</li>
                <li><strong>Derecho de Cancelación:</strong> Solicitud de eliminación de todo registro o copia de sus Datos Personales, ya sea física o electrónica.</li>
                <li><strong>Derecho de Oposición:</strong> Solicitar el cese en el uso de sus Datos Personales.</li>
            </ol>
            <p>El ejercicio de los derechos descritos en el presente numeral se efectúa mediante solicitud por escrito enviada vía correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Ejercicio de Derechos ARCO”. La dirección electrónica mencionada corresponde al departamento que dará trámite a las solicitudes en cuestión y que podrá ser contactado por los Titulares en cualquier tiempo.</p>
            <p>El Titular deberá proporcionar la información siguiente:</p>
            <ol className="latin">
                <li>Nombre completo y domicilio.</li>
                <li>Descripción clara y precisa de los Datos Personales respecto de los cuales pretende ejercer alguno de los Derechos ARCO, además de cualquier documento que permita ubicarlos.</li>
                <li>Mención del Derecho ARCO que desea ejercer y la motivación para hacerlo.</li>
                <li>Los argumentos que sustenten su posición.</li>
                <li>En caso de que se solicite el ejercicio del Derecho de Rectificación, se deberán indicar las modificaciones por realizar y se deberá aportar la documentación que acredite la razón de los cambios solicitados.</li>
            </ol>
            <p>Asimismo, la solicitud deberá acompañarse de los siguientes documentos:</p>
            <ol className="latin">
                <li>Identificación oficial del Titular o de su representante.</li>
                <li>Documento que acredite que la información personal se encuentra en posesión de <strong>QIBO.</strong></li>
                <li>En el supuesto que el Titular no presente personalmente la solicitud, quien lo haga deberá acreditar su representación. Podrá hacerlo mediante escritura pública o carta poder firmada ante dos testigos, junto con identificaciones oficiales de Titular y representante.</li>
            </ol>
            <p>Una vez recibida la solicitud del Titular, <strong>QIBO</strong> le hará llegar vía correo electrónico un acuse de recibo. Posteriormente, responderá a la petición del Titular por correo electrónico en un plazo que no podrá exceder de 20 (veinte) días hábiles contados a partir de que se recibió. Este plazo únicamente será aplicable siempre y cuando <strong>QIBO</strong> cuente con la información suficiente para poder atender la solicitud, en términos de lo establecido en el siguiente párrafo.</p>
            <p>El Titular debe tomar en consideración que, a fin de contar con elementos suficientes para atender su petición, <strong>QIBO</strong> podrá solicitarle información adicional dentro de los 5 (cinco) días hábiles siguientes a la recepción de la solicitud. A partir del requerimiento de <strong>QIBO,</strong> El Titular contará con un plazo de 10 (diez) días hábiles para desahogar este requerimiento. En este supuesto, el plazo de 20 (veinte) días hábiles mencionado en el párrafo anterior empezará a correr al día siguiente de aquél en el que el Titular hubiere atendido el requerimiento de <strong>QIBO.</strong></p>
            <p>Si la solicitud es declarada procedente y el Titular ha acreditado su personalidad y la titularidad del Derecho ARCO que pretendió ejercer, éste se hará efectivo en un plazo máximo de 15 (quince) días hábiles contados a partir de la fecha en que se le haya dado respuesta a la solicitud.</p>
            <p>Tratándose de solicitudes de Acceso a Datos Personales, procederá su entrega previa acreditación de la identidad del solicitante o representante, según corresponda. Dicha entrega se hará en copias simples en el domicilio mencionado en el presente, o bien, en documentos electrónicos a la dirección de correo desde la que se hubiere remitido la solicitud.</p>
            <p>En caso de que el objeto de la solicitud sea la Oposición, el tratamiento de los Datos Personales cesará el día hábil inmediato siguiente a la fecha en la que se le haya comunicado la respuesta afirmativa al Titular.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios y procedimiento para la revocación del procedimiento</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En cualquier momento y por cualquier causa, el Titular podrá revocar la autorización que le ha dado a <strong>QIBO</strong> para el tratamiento de sus Datos Personales. <strong>QIBO</strong> manifiesta que, para el cumplimiento de ciertos fines, la revocación del consentimiento podría significar la imposibilidad de continuar con la relación entre ambas partes.</p>
            <p>En su caso, la revocación del consentimiento se llevará a cabo de acuerdo con el siguiente procedimiento.</p>
            <ol className="latin">
                <li>Envío de un correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Revocación del Consentimiento”.</li>
                <li>En el cuerpo del mensaje se deberá señalar:</li>
                <ol className="roman">
                    <li>El nombre completo del Titular.</li>
                    <li>El motivo de su solicitud.</li>
                    <li>Los argumentos que sustenten su petición.</li>
                    <li>Fecha a partir de la cual desea que se haga efectiva la revocación.</li>
                    <li>Documento oficial que acredite su identidad, que se deberá adjuntar a la solicitud.</li>
                </ol>
            </ol>
            <li>En el supuesto que el Titular no presente por su propio derecho la solicitud, quien lo haga deberá acreditar su representación. Podrá hacerlo mediante escritura pública o carta poder firmada ante dos testigos, junto con identificaciones oficiales de Titular y representante.</li>
            <li><strong>QIBO</strong> responderá a su solicitud mediante correo electrónico en un plazo de 20 (veinte) días hábiles contados a partir de la fecha en la que se recibió. Este plazo únicamente será aplicable siempre y cuando <strong>QIBO</strong> cuente con la información suficiente para poder atender la solicitud, en términos de lo establecido en el siguiente párrafo.</li>
            <li>El Titular debe tomar en consideración que, a fin de contar con elementos suficientes para atender su petición, <strong>QIBO</strong> podrá solicitarle información adicional dentro de los 5 (cinco) días hábiles siguientes a la recepción de la solicitud. A partir del requerimiento de <strong>QIBO,</strong> el Titular contará con un plazo de 10 (diez) días hábiles para desahogar este requerimiento. En este supuesto, el plazo de 20 (veinte) días hábiles mencionado en el párrafo anterior empezará a correr al día siguiente de aquél en el que el Titular hubiere atendido el requerimiento de <strong>QIBO.</strong></li>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Responsable de tramitar solicitudes de derechos ARCO, limitación de uso y divulgación, así como revocaciones</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En caso de que el Titular desee revocar su consentimiento, limitar el uso y divulgación de su información, así como Acceder, Rectificar, Cancelar u Oponerse al Tratamiento de sus Datos Personales, lo deberá hacer a través del Departamento designado por <strong>QIBO,</strong> cuyos datos de contacto se enuncian a continuación:</p>
            <p>Responsable: <strong>DEPARTAMENTO DE PROTECCIÓN DE DATOS PERSONALES DE <strong>QIBO.</strong></strong></p>
            <p>Correo electrónico: servicioaclientes@qibo.io</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Medios para limitar el uso o divulgación de los datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p><strong>QIBO,</strong> en aras de garantizar la protección de los Datos Personales de los Titulares, se compromete a la celebración de actos jurídicos, la adopción de políticas de privacidad, de protección de Datos Personales y de seguridad de la información exigibles al interior de su organización, así como a implementar medidas de seguridad de carácter tecnológico, físico y administrativo.</p>
            <p><strong>QIBO</strong> almacena la información personal en bases de datos con acceso limitado que se encuentran en instalaciones controladas con mecanismos de seguridad. <strong>QIBO</strong> se compromete a que la información proporcionada por el Titular sea considerada y tratada con carácter confidencial.</p>
            <p><strong>QIBO</strong> brinda mecanismos alternos al ejercicio de Derechos ARCO para que los Titulares limiten el uso y divulgación de sus Datos Personales. En este sentido, hace del conocimiento del Titular que, para evitar recibir publicidad, puede inscribirse en el Registro Público para Evitar Publicidad (REPEP) de la Procuraduría Federal del Consumidor (PROFECO), en el vínculo https://repep.profeco.gob.mx/</p>
            <p>En caso de que el Titular desee conocer más sobre lo abordado en este apartado, podrá enviar un correo electrónico a la dirección servicioaclientes@qibo.io con el asunto “Limitación de Uso o Divulgación”, expresando sus dudas, comentarios y sugerencias con respecto al objeto del presente.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Plazo de almacenamiento de datos personales</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>En cumplimiento al principio de calidad, todos los Datos Personales del Titular serán resguardados por el tiempo necesario para cumplir con las finalidades para las que fueron recabados. Posteriormente, se procederá a un periodo de bloqueo previo a su cancelación.</p>
        </div>
        <div className="column c12 PrivacyPage__section--title">
            <h3>Modificaciones al aviso de privacidad</h3>
        </div>
        <div className="column c11 PrivacyPage__section--text">
            <p>Se hace del conocimiento del Titular que el presente Aviso de Privacidad podrá ser modificado en el tiempo y forma que <strong>QIBO</strong> lo determine, atendiendo en todo caso a la legislación en la materia.</p>
            <p><strong>QIBO</strong> informa a los Titulares que los cambios al presente serán notificados mediante la publicación de un mensaje informativo y de su versión actualizada en el Sitio Web y/o mediante el envío de un correo electrónico a la dirección que el Titular hubiere proporcionado.</p>
        </div>
    </>)


    return (<>

        <Header />

        <div className="container mb100">
            <div className="column c12 TermsPage__title">
                <h1 className="mb00">Aviso de<br /><span className="txt--accent">privacidad</span></h1>
                <p className="p--gray taLeft"><strong>Última actualización:</strong> 26 (veintiséis) de abril de 2021 (dos mil veintiuno)</p>
            </div>
            <div className="column c12 TermsPage__body px00 aCenter">
                <Multibutton>
                    <Button
                        css={`btn ${type === 'client' ? undefined : 'btn--ghost'}`}
                        action={() => setType('client')}
                    >
                        Para clientes
                    </Button>
                    <Button
                        css={`btn ${type === 'interviewee' ? undefined : 'btn--ghost'}`}
                        action={() => setType('interviewee')}
                    >
                        Para entrevistados
                    </Button>
                </Multibutton>
                {type === 'client' && <h3>Aviso de privacidad para clientes de Qibo<sup>®</sup></h3>}
                {type === 'interviewee' && <h3>Aviso de privacidad para entrevistados de Qibo<sup>®</sup></h3>}
                {type === 'client' && <ClientsPrivacy />}
                {type === 'interviewee' && <IntervieweePrivacy />}
            </div>
        </div>

        <Footer />

    </>)
}

export default PrivacyPage
