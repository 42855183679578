import React, { useEffect, useState } from 'react'
import './NewsCard.scss'
import { DateTime } from 'luxon'

import Button from '../Button'

import { ReactComponent as IqonArrow} from '../../assets/iqons/iqon_long-arrow-right.svg'


const NewsCard = ({ data }) => {

    const image = require(`../../assets/images/${data.top_image}`).default

    const [publicationDate, setPublicationDate] = useState(null)


    const monthChooser = date => {
        if (date === 1) return 'enero'
        else if (date === 2) return 'febrero'
        else if (date === 3) return 'marzo'
        else if (date === 4) return 'abril'
        else if (date === 5) return 'mayo'
        else if (date === 6) return 'junio'
        else if (date === 7) return 'julio'
        else if (date === 8) return 'agosto'
        else if (date === 9) return 'septiembre'
        else if (date === 10) return 'octubre'
        else if (date === 11) return 'noviembre'
        else if (date === 12) return 'diciembre'
    }

    useEffect( () => {
        const publication = DateTime.fromSeconds(data.publication_date.seconds)
        const month = monthChooser(publication.month)
        setPublicationDate(`${publication.day} de ${month} de ${publication.year}`)
    }, [data])


    return (
        <div className="row c04 NewsCard__container">
            <div className="column c12 NewsCard__card p00 shadow--grow">
                <div className="row c12 NewsCard__image"
                    style={{backgroundImage: `url(${image})`}}
                />
                <div className="column c12 NewsCard__content">
                    <div className="row c12 NewsCard__logo">
                        <img src={require(`../../assets/images/${data.top_logo}`).default} alt="" />
                    </div>
                    <div className="row c12 NewsCard__headline">
                        <p className="m00 font--bold">{data.headline}</p>
                    </div>
                    <div className="row c12 NewsCard__date py00">
                        <span className="m00">{publicationDate}</span>
                    </div>
                    <div className="row c12 NewsCard__btn">
                        <Button
                            css="btn btn--ghost m00"
                            action={data.link}
                            target="_blank"
                        >
                            Leer más
                            <IqonArrow />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsCard
