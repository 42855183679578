import React from 'react'
import { useAppWidth } from '../../Provider'
import './Footer.scss'

import Button from '../Button'

import logo from '../../assets/images/logo_Qibo-io.svg'


const Footer = () => {

    const vw = useAppWidth()


    return (
        <div className="row c12 Footer__container p00 pt100">
            <div className={`row container Footer__links
                ${vw < 768 ? 'px30' : undefined}
            `}>
                <div className="column c03s Footer__company--container aFlex-start">
                    <img src={logo} alt="Qibo.io logo" className="Footer__company--logo mb50" />
                </div>
                <div className="column c03s Footer__sections--container">
                    <p className="Footer__sections--title">Soluciones</p>
                    <Button css="Footer__sections--link" action="/biz">Empresarial</Button>
                    <Button css="Footer__sections--link" action="/home">Hogar</Button>
                    {/* <p className="Footer__sections--link">Call center</p> */}
                    {/* <p className="Footer__sections--link">Arrendamiento</p> */}
                </div>
                <div className="column c03s Footer__sections--container">
                    <p className="Footer__sections--title">Más</p>
                    <Button css="Footer__sections--link" action="/help-center">Ayuda</Button>
                    <Button css="Footer__sections--link" action="/contact">Contacto</Button>
                    {/* <p className="Footer__sections--link">Blog</p> */}
                    {/* <p className="Footer__sections--link">Únete al equipo</p> */}
                </div>
                <div className="column c03s Footer__sections--container">
                    <p className="Footer__sections--title">Síguenos</p>
                    {/* <p className="Footer__sections--link">Facebook</p> */}
                    <Button
                        css="Footer__sections--link"
                        action="https://www.linkedin.com/company/65397082/"
                        target="_blank"
                    >
                        LinkedIn
                    </Button>
                    <Button
                        css="Footer__sections--link"
                        action="https://www.youtube.com/channel/UCfeeq_mkwIrFaiGG0PznFaA"
                        target="_blank"
                    >
                        YouTube
                    </Button>
                </div>
                <div className={`row c12 Footer__legal--conatiner pt20 mt50 mb100
                    ${vw >= 768 ? 'px00' : undefined}
                `}>
                    <Button css="Footer__legal--link" action="/privacy">Aviso de privacidad</Button>
                    <Button css="Footer__legal--link" action="/terms">Términos y condiciones de uso</Button>
                    <p className="Footer__legal--text">©{new Date().getFullYear()} Especialistas en Prevención y Análisis, S. A. de C. V.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
