import React from 'react'
import { Link } from 'react-router-dom'
import './Button.scss'

const Button = ({ action, children, css, inlineStyle, target, type}) => {
    return (<>
        {(typeof action === 'function' || type === 'submit')
            && <button
            className={css || ''}
            style={inlineStyle}
            onClick={action}
            type={type || 'button'}
        >
            {children}
        </button>}
        {typeof action === 'string' && action.slice(0, 4) === 'http'
            && <a
                className={css || ''}
                style={inlineStyle}
                href={action}
                target={target || '_self'}
                rel="noopener noreferrer"
            >
                {children}
            </a>}
        {((typeof action === 'string' && action.slice(0, 4) !== 'http')
            || typeof action === 'object')
            && <Link
                className={css || ''}
                style={inlineStyle}
                to={action}
            >
                {children}
            </Link>}
    </>)
}

export default Button
