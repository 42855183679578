import React from 'react'
import './LoadingDots.scss'


const LoadingDots = () => {
    return (
        <div className="row LoadingDots__container">
            <span className="LoadingDots__container--dot-01"></span>
            <span className="LoadingDots__container--dot-02"></span>
            <span className="LoadingDots__container--dot-03"></span>
            <span className="LoadingDots__container--dot-04"></span>
            <span className="LoadingDots__container--dot-05"></span>
        </div>
    )
}

export default LoadingDots
