import React from 'react'
import './DeliveryStatus.scss'

import LoadingDots from '../../components/LoadingDots'

import { ReactComponent as IqonApprove } from '../../assets/iqons/iqon_approve.svg'
import { ReactComponent as IqonBrokenHeart } from '../../assets/iqons/iqon_broken-heart.svg'


const DeliveryStatus = ({ error, success }) => {

    const renderStatus = () => {
        if (error) return (<>
            <IqonBrokenHeart />
            <p className="DeliveryStatus__title">Oooops!</p>
            <p>Algo salió mal y no se pudo enviar tu solictud.</p>
            <p>¿Te parece si lo vuelves a intentar más tarde?</p>
        </>)
        else if (success) return (<>
            <IqonApprove />
            <p className="DeliveryStatus__title">¡Todo listo!</p>
            <p>Un experto ya recibió tu solicitud.</p>
            <p>Pronto serás contactado por el medio que seleccionaste.</p>
        </>)
        else return (<>
            <LoadingDots />
            <p style={{marginTop: '20px'}}>Espera un momento, estamos enviando tu solicitud...</p>
        </>)
    }


    return (
        <div className="column c12 DeliveryStatus__container">
            {renderStatus()}
        </div>
    )
}

export default DeliveryStatus
