
const bug_valid = (values) => {
    let errors = {}
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// First name validation
    if (!values.first_name) errors.first_name = 'Por favor escribe tu nombre'
    else if (values.first_name.trim().length < 2) errors.first_name = 'El nombre que escribiste es muy corto'

// Last name validation
    if (!values.last_name) errors.last_name = 'Por favor escribe tu apellido'
    else if (values.last_name.trim().length < 2) errors.last_name = 'El apellido que escribiste es muy corto'

// Email validation
    if (!values.email) errors.email = 'Por favor escribe tu email de usuario de Qibo® App'
    else if (!emailRegex.test(values.email)) errors.email = 'Por favor escribe una dirección de email válida'

// Category validation
    if (!values.category) errors.category = 'Por favor selecciona una categoría'

// First name validation
    if (!values.subject) errors.subject = 'Por favor escribe un resumen'
    else if (values.subject.trim().length < 10) errors.subject = 'El resumen que escribiste es muy corto'

// Comments validation
    if (!values.description) errors.description = 'Por favor escribe una descripción'

    return errors
}

export default bug_valid