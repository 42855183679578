import React from 'react'
import './Slot.scss'


const Slot = ({ center, children, Iqon, number, title, titleHeight }) => {
    return (
        <div className={`column c12 Slot__container ${center ? 'aCenter' : undefined}`}>
            {Iqon && <Iqon className="Slot__iqon" />}
            {number && <span className="Slot__number">{number}</span>}
            <p
                className={`Slot__title ${center ? 'taCenter' : undefined}`}
                style={{minHeight: titleHeight ? `${titleHeight}em`: '1em'}}
            >
                    {title}</p>
            <p className={`Slot__description p--description ${center ? 'taCenter' : undefined}`}>
                {children}
            </p>
        </div>
    )
}

export default Slot
