import React, { useEffect, useState} from 'react'
import './NewsRow.scss'
import { DateTime } from 'luxon'

import Button from '../Button'


const NewsRow = ({ data, first }) => {

    const [publicationDate, setPublicationDate] = useState(null)


    const monthChooser = date => {
        if (date === 1) return 'enero'
        else if (date === 2) return 'febrero'
        else if (date === 3) return 'marzo'
        else if (date === 4) return 'abril'
        else if (date === 5) return 'mayo'
        else if (date === 6) return 'junio'
        else if (date === 7) return 'julio'
        else if (date === 8) return 'agosto'
        else if (date === 9) return 'septiembre'
        else if (date === 10) return 'octubre'
        else if (date === 11) return 'noviembre'
        else if (date === 12) return 'diciembre'
    }

    useEffect( () => {
        const publication = DateTime.fromSeconds(data.publication_date.seconds)
        const month = monthChooser(publication.month)
        setPublicationDate(`${publication.day} de ${month} de ${publication.year}`)
    }, [data])


    return (
        <div className={`column c12 NewsRow__container ${first && 'first'}`}>
            <p className="NewsRow__brand my15">{data.media_name}</p>
            <p className="NewsRow__headline my10 font--bold">
                <Button
                    css="NewsRow__headline--link"
                    action={data.link}
                    target="_blank"
                >
                    {data.headline}
                </Button>
            </p>
            <p className="NewsRow__date mt00 mb20">{publicationDate}</p>
            {data.tags.length > 0 && <div className="row c12 NewsRow__tags p00 mb15">
                {data.tags.map( (i, idx) => <span key={idx}>{i}</span>)}
            </div>}
        </div>
    )
}

export default NewsRow
