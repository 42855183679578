import React, { useEffect, useState } from 'react'
import './SalesHomeForm.scss'

import useMailing from '../../hooks/useMailing'

import salesHome_valid from '../../hooks/useValid/salesHome_valid'
import useValid from '../../hooks/useValid'

import Button from '../../components/Button'
import DeliveryStatus from '../../components/DeliveryStatus'
import RadioList from '../../components/RadioList'
import Select from '../../components/Select'

import { ReactComponent as IqonDanger } from '../../assets/iqons/iqon_danger_stroke.svg'


const SalesHomeForm = () => {

    const [initialState] = useState({
        comments: '',
        contact_channel: 'call',
        contact_channel_text: 'Llamada',
        country: '',
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        sales_interests: []
    })
    const [contactChannel] = useState([
        {
            text: 'Llamada',
            value: 'call'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'WhatsApp',
            value: 'whatsapp'
        }
    ])
    const [salesBizInterests] = useState([
        {
            text: '¿Cómo empiezo a usar Qibo?',
            value: 'letsStart'
        },
        {
            text: 'La tecnología de Qibo',
            value: 'qiboTechnology'
        },
        {
            text: 'Evaluar al personal',
            value: 'evaluation'
        },
        {
            text: 'Entrevistas para contratación',
            value: 'recruitment'
        },
        {
            text: 'Arrendamiento de inmuebles',
            value: 'rent'
        },
        {
            text: 'Personal del hogar',
            value: 'house-personel'
        },
        {
            text: 'Personal esporádico',
            value: 'intermitent-personel'
        },
        {
            text: '¿Qué temas se evalúan?',
            value: 'topics'
        }
    ])

    const [delivery, setDelivery] = useState(null)

    const { formData, forceTouch, handleBlur, handleChange } = useValid(initialState, salesHome_valid)
    const { contactHomeRep } = useMailing()

    const input_data = formData.input_data
    const errors = formData.errors_data
    const touched = formData.touched_fields
    const valid = formData.validation.may_submit

    const delivered = delivery && delivery.delivery && delivery.delivery.state === 'SUCCESS'
    const undelivered = delivery && delivery.delivery && delivery.delivery.state === 'ERROR'

    const handleChannelPlaceholder = () => {
        if (input_data.contact_channel === 'call') return 'Teléfono o celular *'
        else if (input_data.contact_channel === 'email') return 'Email *'
        else if (input_data.contact_channel === 'whatsapp') return 'Celular *'
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (!valid) {
            forceTouch('country')
            forceTouch('email')
            forceTouch('first_name')
            forceTouch('last_name')
            forceTouch('phone_number')
            forceTouch('sales_interests')
        }
        else if (valid) {
            setDelivery(true)
            window.scrollTo(0, 0)
            contactHomeRep(
                input_data.comments,
                input_data.contact_channel_text,
                input_data.country,
                input_data.email,
                input_data.first_name,
                input_data.last_name,
                input_data.phone_number,
                input_data.sales_interests,
            ).then( r => {
                r.onSnapshot( snapshot => {
                    console.log('mail', snapshot.data())
                    setDelivery(snapshot.data())
                })
            })
        }
    }

    const formRender = () =>
        <form onSubmit={handleSubmit} className="row c12 SalesHomeForm__container p00">
            <div className="row c06 SalesHomeForm__field--container py10">
                <input
                    className={`
                        ${!errors.first_name && touched.first_name && 'success'}
                        ${errors.first_name && touched.first_name && 'error'}
                    `}
                    name="first_name"
                    type="text"
                    placeholder="Nombre *"
                    value={input_data.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.first_name && touched.first_name && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.first_name}
                </span>}
            </div>
            <div className="row c06 SalesHomeForm__field--container py10">
                <input
                    className={`
                        ${!errors.last_name && touched.last_name && 'success'}
                        ${errors.last_name && touched.last_name && 'error'}
                    `}
                    name="last_name"
                    type="text"
                    placeholder="Apellido *"
                    value={input_data.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.last_name && touched.last_name && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.last_name}
                </span>}
            </div>
            <div className="column c12 SalesHomeForm__field--container pt10 pb05 px30">
                <p className="SalesHomeForm__label">¿Cómo quieres que te contactemos?</p>
            </div>
            <div className="row c04s SalesHomeForm__field--container py10">
                <Select
                    activeValue={input_data.contact_channel_text}
                    placeOption="Contacto *"
                    height="200px"
                    slice={20}
                >
                    {contactChannel.map( (i, idx) =>
                        <span
                            className={`${input_data.contact_channel === i.value ? 'active' : null}`}
                            inside="true"
                            key={idx}
                            onClick={() => {
                                handleChange({target: {
                                    name: 'contact_channel',
                                    value: i.value
                                }})
                                handleChange({target: {
                                    name: 'contact_channel_text',
                                    value: i.text
                                }})
                                setTimeout( () => {
                                    forceTouch('role')
                                }, 50)
                            }}
                        >
                            {i.text}
                        </span>
                    )}
                </Select>
            </div>
            <div className="row c08s SalesHomeForm__field--container py10">
                <input
                    className={`
                        ${['call', 'whatsapp'].includes(input_data.contact_channel)
                            && !errors.phone_number && touched.phone_number && 'success'}
                        ${input_data.contact_channel === 'email'
                            && !errors.email && touched.email && 'success'}
                        ${['call', 'whatsapp'].includes(input_data.contact_channel)
                            && errors.phone_number && touched.phone_number && 'error'}
                        ${input_data.contact_channel === 'email'
                            && errors.email && touched.email && 'error'}
                    `}
                    name={input_data.contact_channel === 'email' ? 'email' : 'phone_number'}
                    type={input_data.contact_channel === 'email' ? 'email' : 'number'}
                    placeholder={handleChannelPlaceholder()}
                    value={input_data.contact_channel === 'email' ? input_data.email : input_data.phone_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {['call', 'whatsapp'].includes(input_data.contact_channel)
                    && errors.phone_number && touched.phone_number && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.phone_number}
                </span>}
                {input_data.contact_channel === 'email'
                    && errors.email && touched.email && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.email}
                </span>}
            </div>
            <div className="row c12 SalesHomeForm__field--container py10">
                <input
                    className={`
                        ${!errors.country && touched.country && 'success'}
                        ${errors.country && touched.country && 'error'}
                    `}
                    name="country"
                    type="text"
                    placeholder="País *"
                    value={input_data.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.country && touched.country && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.country}
                </span>}
            </div>
            <div className={`column c12 SalesHomeForm__field--container pt10 pb05 px30
                ${errors.sales_interests && touched.sales_interests && 'error'}
            `}>
                <p className="SalesHomeForm__label">¿Sobre qué temas quieres información? *</p>
                {(!errors.sales_interests || !touched.sales_interests) && <p className="SalesHomeForm__hint">Puedes elegir más de uno</p>}
                {errors.sales_interests && touched.sales_interests && <span className="error p00 mt05">
                    <IqonDanger className="error" />
                    {errors.sales_interests}
                </span>}
            </div>
            <div className="row c12 SalesHomeForm__field--container py10">
                <RadioList
                    items={salesBizInterests}
                    activeValue={input_data.sales_interests}
                    handleUpdate={ u => {
                        handleChange({target: {
                            name: 'sales_interests',
                            value: u
                        }})
                        setTimeout( () => {
                            forceTouch('sales_interests')
                        }, 50)
                    }}
                />
            </div>
            <div className="row c12 SalesHomeForm__field--container py10">
                <textarea
                    className={`
                        ${!errors.comments && touched.comments && 'success'}
                        ${errors.comments && touched.comments && 'error'}
                    `}
                    name="comments"
                    rows="3"
                    placeholder="¿Algo más que quieras agregar? (opcional)"
                    value={input_data.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div className="row c12 SalesHomeForm__field--container py10">
                <Button
                    css={`btn ${valid ? 'btn--grow' : 'btn--disabled'} mlAuto`}
                    inlineStyle={{fontWeight: 800}}
                    type="submit"
                >
                    Enviar
                </Button>
            </div>
        </form>


    return (<>
        {!delivery ? formRender() : <DeliveryStatus error={undelivered} success={delivered} />}
    </>)
}

export default SalesHomeForm
