import { useEffect, useReducer } from 'react'


const formDataReducer = (s, a) => {
    switch (a.type) {
        case 'ERRORS':
            s.errors_data = a.payload
            s.validation.may_submit = false
            return {...s}
        case 'NO_ERRORS':
            s.errors_data = a.payload
            s.validation.may_submit = true
            return {...s}
        case 'ON_BLUR':
            s.input_data[a.payload.name] = a.payload.value.trim()
            s.touched_fields[a.payload.name] = true
            return {...s}
        case 'ON_CHANGE':
            s.input_data[a.payload.name] = a.payload.value
            return {...s}
        case 'FORCE_TOUCH':
            s.touched_fields[a.payload.name] = true
            return {...s}
        case 'RESET':
            return {
                errors_data: {},
                touched_fields: {},
                validation: {may_submit: false},
                input_data: a.payload
            }
        case 'RESET_VALUE': 
            s.input_data[a.payload.name] = ''
            s.touched_fields[a.payload.name] = false
            return {...s}
        default:
            return {...s}
    }
}


const useValid = ( initialState, validation ) => {
    
    const [formData, dispatchFormData] = useReducer(formDataReducer, {
        errors_data: {},
        input_data: initialState,
        touched_fields: {},
        validation: {may_submit: false}
    })

    useEffect( () => {
        const newErrors = validation(formData.input_data)
        if (Object.keys(newErrors).length === 0) {
            dispatchFormData({
                type: 'NO_ERRORS',
                payload: newErrors
            })
        } else {
            dispatchFormData({
                type: 'ERRORS',
                payload: newErrors
            })
        }
    }, [])

    const handleAction = (a, t) => {
        dispatchFormData({
            type: a,
            payload: {
                name: t.name,
                value: t.value
            }
        })
        setTimeout( () => {
            const newErrors = validation(formData.input_data)
            if (Object.keys(newErrors).length === 0) {
                dispatchFormData({
                    type: 'NO_ERRORS',
                    payload: newErrors
                })
            } else {
                dispatchFormData({
                    type: 'ERRORS',
                    payload: newErrors
                })
            }
        }, 50)
    }

    const forceTouch = (value) => dispatchFormData({type: 'FORCE_TOUCH', payload: { name: value}})

    const handleChange = ({ target }) => handleAction('ON_CHANGE', target)

    const handleBlur = ({ target }) => handleAction('ON_BLUR', target)

    const handleReset = () => dispatchFormData({type: 'RESET', payload: initialState})

    const resetValue = (value) => dispatchFormData({type: 'RESET_VALUE', payload: { name: value}})


    return { forceTouch, handleBlur, handleChange, handleReset, formData, resetValue }
}

export default useValid