import React, { useState } from 'react'
import './ExpandableRow.scss'

import { ReactComponent as IqonOpen } from '../../assets/iqons/iqon_open.svg'


const ExpandableRow = ({ answer, question }) => {

    const [viewAnswer, setViewAnswer] = useState(false)


    return (
        <div className="row c12 ExpandableRow__container mb20" onClick={() => setViewAnswer(!viewAnswer)}>
            <span className="ExpandableRow__question">{question}</span>
            <IqonOpen className={`ExpandableRow__iqon ${viewAnswer ? 'active' : null}`} />
            {viewAnswer && <span className={`ExpandableRow__answer`} dangerouslySetInnerHTML={{__html: answer}}/>}
        </div>
    )
}

export default ExpandableRow
