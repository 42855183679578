import React, { useState } from 'react'
import { useAppWidth } from '../../Provider'
import './ContactPage.scss'

import SalesBizForm from '../../forms/SalesBizForm'
import SalesHomeForm from '../../forms/SalesHomeForm'

import Footer from '../../components/Footer'
import Header from '../../components/Header'

import { ReactComponent as IqonRight } from '../../assets/iqons/iqon_arrow-right.svg'


const ContactPage = ({ location }) => {

    const vw = useAppWidth()

    const [formType, setFormType] = useState(location.state ? location.state.form : 'salesBiz')


    return (<>

        <Header />

        <div className="container ContactPage__conatiner mb100">
            <div className={`column c06l c05m ContactPage__left-container
                ${vw >= 992 ? 'py80' : 'pt80 pb30'}
            `}>
                <div className="row c12 ContactPage__title p00">
                    <h1>Estamos listos. Trabajemos <span className="txt--accent">juntos</span></h1>
                </div>
                {vw >= 992 && <div className="column c12 ContactPage__choose p00 pt30">
                    <p className="pl15 font--black">Cuéntanos, sobre qué tema quisieras platicar:</p>
                    <span
                        className={`ContactPage__chooser--item ${formType === 'salesBiz' && 'active'}`}
                        onClick={() => setFormType('salesBiz')}
                    >
                        <span>Qibo<sup>®</sup> en mi empresa</span>
                        <IqonRight />
                    </span>
                    <span
                        className={`ContactPage__chooser--item ${formType === 'salesHome' && 'active'}`}
                        onClick={() => setFormType('salesHome')}
                    >
                        <span>Qibo<sup>®</sup> en mi hogar</span>
                        <IqonRight />
                    </span>
                </div>}
            </div>
            {vw < 992 &&
                <div className="column c06l c05m ContactPage__options p00">
                    <div className="column c12 ContactPage__chooser p00">
                        <p className="px15 font--black">Cuéntanos, sobre qué tema quisieras platicar:</p>
                        <div className="c12 column ContactPage__chooser--scroller p00 jcCenter aFlex-start">
                            <span
                                className={`ContactPage__chooser--pill ${formType === 'salesBiz' && 'active'}`}
                                onClick={() => setFormType('salesBiz')}
                            >
                                <span>Qibo<sup>®</sup> en mi empresa</span>
                            </span>
                            <span
                                className={`ContactPage__chooser--pill ${formType === 'salesHome' && 'active'}`}
                                onClick={() => setFormType('salesHome')}
                            >
                                <span>Qibo<sup>®</sup> en mi hogar</span>
                            </span>
                        </div>
                    </div>
                </div>}
            <div className={`row c06l c07m c08s ContactPage__form aCenter mxAuto
                ${vw >= 992 ? 'my60' : 'mt10 mb60'}
            `}>
                {formType === 'salesBiz' && <SalesBizForm />}
                {formType === 'salesHome' && <SalesHomeForm />}
            </div>

            <div className="column c12 ContactPage__phone aCenter">
                <h2 className="mb00">Línea Qibo<sup>®</sup></h2>
                <p className="ContactPage__phone--message">
                    Si lo prefieres, estaremos felices de saber de ti
                    {vw >= 768 && <br />} a través de nuestra línea Qibo<sup>®</sup>
                </p>
                <span className="ContactPage__phone--number">+52 (55) 7005 0517</span>
            </div>
        </div>

        <Footer />

    </>)
}

export default ContactPage
